<div class="singel-course">
    <a href="#" class="w-100" data-toggle="tooltip" title={{title}}>
        <div class="thum">
            <div class="image">
                <img src='{{cardImage}}' alt="{{title}}">
            </div>
            <!-- <div class="price">
                <span>Free</span>
            </div> -->
        </div>
        <div class="cont">
            <div>
                <h4 class="pb-0"> {{title}} </h4>
                <span>{{language}}</span>
                <p class="pt-15"> {{details}}</p>
                <a href="#">See Project</a>
            </div>
        </div>
    </a>
</div>