import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  regisForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private auth: AuthService) {
    this.regisForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      loc: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  ngOnInit(): void {
  }

  onSubmit(valid, data) {
    
    let mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let re = /[0-9]/;
    let re1 = /[a-z]/;
    let re2 = /[A-Z]/;

    if (valid) {
      if (mailformat.test(data['email']) == false) {
        alert("Error: You have entered an invalid email address!");
        return false;
      } else if (data['password'].length < 6) {
        alert("Error: Password must contain at least six characters!");
        return false;
      }
      else if (!re.test(data['password'])) {
        alert("Error: password must contain at least one number (0-9)!");
        return false;
      }
      else if (!re1.test(data['password'])) {
        alert("Error: password must contain at least one lowercase letter (a-z)!");
        return false;
      }
      else if (!re2.test(data['password'])) {
        alert("Error: password must contain at least one uppercase letter (A-Z)!");
        return false;
      }
      else if (data['phone'].length < 10 || data['phone'].length > 10) {
        alert("Error: Mobile Number must contain 10 digits!");
        return false;
      }
      else if (isNaN(Number(data['phone']))) {
        alert("Error: Mobile Number must be number!");
        return false;
      }
      else {
        this.auth.registerAdmin(data)
          .then(data => {
            console.log(data)
          },
            error => {
              alert(error.message)
            });
      }
    } else {
      alert("Error: Please check all details!");
    }
  }

}
