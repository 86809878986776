import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.css']
})
export class BlogPageComponent implements OnInit {

  blogList = [
    {
      "title": "Tech Summit1",
      "image": 'assets/images/blog/e-1.jpg',
      "desc": 'Nam nec tellus a odio tincidunt auctor a ornare odionon mauris itae erat conuat.',
      "date": '1 December 2018',
      "time": '10:00 Am - 3:00 Pm',
    },
    {
      "title": "Recruitment",
      "image": 'assets/images/blog/e-1.jpg',
      "desc": 'Nam nec tellus a odio tincidunt auctor a ornare odionon mauris itae erat conuat.',
      "date": '2 December 2018',
      "time": '11:00 Am - 3:00 Pm',
    },
    {
      "title": "WorkDay",
      "image": 'assets/images/blog/e-1.jpg',
      "desc": 'Nam nec tellus a odio tincidunt auctor a ornare odionon mauris itae erat conuat.',
      "date": '3 December 2018',
      "time": '12:00 Am - 3:00 Pm',
    },
    {
      "title": "App Development",
      "image": 'assets/images/blog/e-1.jpg',
      "desc": 'Nam nec tellus a odio tincidunt auctor a ornare odionon mauris itae erat conuat.',
      "date": '4 December 2018',
      "time": '01:00 Am - 3:00 Pm',
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
