<div class="singel-testimonial">
    <div class="testimonial-thum">
        <img src="assets\images\all-icon\expert.png" alt="Testimonial">
        <div class="quote">
            <i class="fa fa-quote-right"></i>
        </div>
    </div>
    <div class="testimonial-cont">
        <p> {{comments}} </p>
        <!-- <h6>{{name}}</h6> -->
        <!-- <span>{{education}}</span> -->
    </div>
</div>