import { Component, OnInit, Input } from '@angular/core';
import { DummydataService } from '../../dummydata.service';

@Component({
  selector: 'app-featured-courses',
  templateUrl: './featured-courses.component.html',
  styleUrls: ['./featured-courses.component.css']
})
export class FeaturedCoursesComponent implements OnInit {
  @Input() data: any[];
  @Input() arrayIndex: any;
  @Input() title: string;
  @Input() tuitor: string;
  @Input() cardImage: any;
  @Input() reviewCount: number;
  @Input() studentsCount: number;
  @Input() favouriteCount: number;
  @Input() courseId:any;

  // productId: number;
  // productName: string;
  // productPrice: number;
  // productDesc: string;

  arrayOne(n: number): any[] {
    return Array(n);
  }

  constructor() {}

  ngOnInit(): void {

  }

}
