import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map'

export interface Parent {
  course_title: string;
  trainer_title: string;
  id?: string;
}

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyCoursesComponent implements OnInit {

  completedCourse = [];
  ongoingCourse = [];

  completeData = false;
  ongoingData = false;

  showCardContent = true;

  courseContent = [];

  constructor(private afs: AngularFirestore, private router: Router) { }

  async ngOnInit(): Promise<void> {

    var udata = JSON.parse(localStorage.getItem('studentMaster'))
    var courseId = [];
    let service: AngularFirestoreCollection<Parent>;
    let service1: AngularFirestoreCollection<Parent>;

    if (udata != undefined && udata != null) {
      const admin = await this.afs.doc(`StudentMaster/${udata.id}`);
      await admin.valueChanges().subscribe(async rdata => {
        this.completedCourse = [];
        this.ongoingCourse = [];
        console.log(rdata);
        courseId = rdata['courseData'];      
        service = this.afs.collection<Parent>('TrainerCourseAssignMaster', res => {
          return res.where('status', '==', 'completed').orderBy('createdAt', 'asc');
        });
    
        service1 = this.afs.collection<Parent>('TrainerCourseAssignMaster', res => {
          return res.where('status', '==', 'upcoming').orderBy('createdAt', 'asc');
        });
        if (courseId.length > 0) {
          this.completedCourse = [];
          this.ongoingCourse = [];
          console.log(this.ongoingCourse);
          for (let i = 0; i < courseId.length; i++) {
            console.log(courseId[i]);
            service.doc(courseId[i]).get().subscribe(doc => {
              let data = doc.data()
              console.log(doc.data())
              if (data['status'] == 'completed') {
                this.completeData = true;
                this.completedCourse.push(data);
              }
    
            })
            service1.doc(courseId[i]).get().subscribe(doc => {
              let data = doc.data()
              if (data['status'] != 'completed') {
                this.ongoingData = true;
                this.ongoingCourse.push(data);
              }
            })
          }
        }
      });
    } else {
      this.router.navigate(['/signIn']);
    }



   
  }

  showDetail(index){
    this.courseContent = [];
    this.courseContent = this.ongoingCourse[index]['course_syllabus'];
    this.showCardContent = false;
  }

  showCard() {
    if(!this.showCardContent){
      this.showCardContent = true;
      this.ngOnInit()
    }
  }
}