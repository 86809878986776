import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { ToastrService } from 'ngx-toastr';

declare var Razorpay: any;

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css']
})
export class PaymentGatewayComponent implements OnInit {

  subscribeForm: FormGroup;
  userData: any;
  courseDetails: any;
  paymentSuccess = false;

  constructor(private formBuilder: FormBuilder, private auth: AuthService, private afs: AngularFirestore) {
    this.subscribeForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      id: ['', Validators.required],
    })
    
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('studentMaster'));
    console.log(this.userData)
    if (this.userData['id'] != undefined) {
      
      this.subscribeForm.get('name').setValue(this.userData?.student_title != null ? this.userData?.student_title : this.userData?.name);
      this.subscribeForm.get('email').setValue(this.userData?.student_email != null ? this.userData?.student_email : this.userData?.email);
      this.subscribeForm.get('phone').setValue(this.userData?.student_mob != null ? this.userData?.student_mob : this.userData?.phone);
      this.subscribeForm.get('id').setValue(this.userData['id']);
    }

    let courseId = localStorage.getItem('courseDetail');
    if (courseId != null) {
      this.getSingleService(courseId).then(data => {
        data.subscribe(singleservice => {
          console.log(singleservice);
          this.courseDetails = singleservice;
        });
      });
    }
  }

  async getSingleService(id) {
    const get = await this.afs.doc(`TrainerCourseAssignMaster/${id}`);
    const getdata = await get.valueChanges();
    return getdata;
  }

  onSubmit(valid, data) {
    if (valid) {
      var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (mailformat.test(data['email']) == false) {
        alert("Error: You have entered an invalid email address!");
        return false;
      } else if (data['phone'].length < 10 || data['phone'].length > 10) {
        alert("Error: Mobile Number must contain 10 digits!");
        return false;
      }
      else if (isNaN(Number(data['phone']))) {
        alert("Error: Mobile Number must be number!");
        return false;
      } else {
        data['courseId'] = localStorage.getItem('courseDetail');
        var options = {
          "key": "rzp_live_rRDDo61wqyBNld",
          "amount": Number(this.courseDetails['course_offerfees']) * 100,
          "name": data['name'],
          "description": this.courseDetails['course_name'],

          "handler": async (payment_id) => {
            data['payment_id'] = payment_id.razorpay_payment_id;
            this.paymentSuccess = true;
            this.auth.proceedToCheckout(data)
              .then(data => {
                console.log(data)
              },
                error => {
                  alert(error.message)
                });

          },
          "prefill": {
            "name": data['name'],
            "email": data['email'],
            "contact": data['phone'],

          },
          "notes": {},
          "theme": {
            "color": "blue"
          }
        };
        var rzp1 = new Razorpay(options);
        rzp1.open();
      }
    } else {
      alert("Error: Please check all details!");
    }
  }

}
