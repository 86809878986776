
<section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8"
    style="background-image: url(assets/images/page-banner-2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>My Courses</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">My Courses</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="corses-singel" class="pt-90 pb-120 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="corses-tab mt-30">
                    <ul class="nav nav-justified" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a  class="active" id="ongoing-tab" data-toggle="tab" href="#ongoing" role="tab" aria-controls="ongoing"
                                aria-selected="true" (click)="showCard()">On Going Courses</a>
                        </li>
                        <li class="nav-item">
                            <a id="completed-tab" data-toggle="tab" href="#completed" role="tab"
                                aria-controls="completed"  aria-selected="false" (click)="showCard()">Completed Courses</a>
                        </li>
                        
                        <!-- <li class="nav-item">
                            <a id="upcoming-tab" data-toggle="tab" href="#upcoming" role="tab" aria-controls="upcoming" aria-selected="false">Upcoming Courses</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="corses-tab">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" id="completed" role="tabpanel"
                            aria-labelledby="completed-tab">
                            <!-- <div class="corses-singel-left"> -->
                            <div class="row">
                                <div *ngIf="completedCourse.length > 0">
                                    <div class="col-lg-4 cardData col-md-6 mt-30" *ngFor="let course of completedCourse">
                                        <div class="singel-course">
                                            <a [routerLink]="['CourseDetails/',course.id]" class="w-100"
                                                data-toggle="tooltip" title={{course.course_name}}>
                                                <div class="thum">
                                                    <div class="image">
                                                        <img [src]='course.course_image' alt="{{course.course_name}}">
                                                    </div>
                                                </div>
                                                <div class="cont">
                                                    <ul>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <span>({{4}} Reviews)</span>
                                                    <div>

                                                        <h4> {{course.course_name}} </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-lg-12 text-center" *ngIf="completedCourse.length == 0">
                                    No Course Found
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="tab-pane fade show active" id="ongoing" role="tabpanel" aria-labelledby="ongoing-tab">
                            <div class="row" *ngIf="showCardContent">
                                <div *ngIf="ongoingCourse.length > 0">
                                    <div class="col-lg-4 cardData col-md-6 mt-30" *ngFor="let course of ongoingCourse; let i=index">
                                        <div class="singel-course">
                                            <a class="w-100"
                                                data-toggle="tooltip" title={{course.course_name}}>
                                                <div class="thum">
                                                    <div class="image">
                                                        <img [src]="course ? course.course_image : 'assets/images/slider/s-3.jpg'" alt="{{course.course_name}}">
                                                    </div>
                                                </div>
                                                <div class="cont">
                                                    <!-- <ul>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                        <li><i class="fa fa-star"></i></li>
                                                    </ul>
                                                    <span>({{4}} Reviews)</span> -->
                                                    <div>

                                                        <h4> {{course.course_name}} </h4>
                                                    </div>
                                                    <div>
                                                        <span class="main-btn enroll-btn text-center"
                                                    (click)="showDetail(i)">View Detail</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-lg-12 text-center" *ngIf="ongoingCourse.length == 0">
                                    No Course Found
                                </div>
                            </div>
                            <div class="corses-singel-left" *ngIf="!showCardContent">
                                <div class="corses-tab">
                                    <div class="curriculam-cont">
                                        <div class="accordion" id="accordionExample">
                                            <div class="card" *ngFor="let syllabus of courseContent; let i=index">
                                                <div class="card-header">
                                                    <a href="#" data-toggle="collapse" class="collapsed"
                                                        [attr.data-target]="'#collapse'+i" aria-expanded="true">
                                                        <ul>
                                                            <li><span class="head"><i
                                                                class="fa fa-file-o"></i>{{syllabus.title}}</span></li>
                                                            <li>
                                                                <h5 class="time d-none d-md-block theme_primary">
                                                                    {{syllabus.status == false ? 'Under Progress' : 'Completed' }} </h5>
                                                            </li>
                                                        </ul>
                                                    </a>
                                                </div>
                                                <div [attr.id]="'collapse'+i" class="collapse show"
                                                    data-parent="#accordionExample">
                                                    <div class="card-body">
                                                        <div class="container-fluid">
                                                            <div [class]="syllabus_subtitle.status == true ? 'step completed' : 'step active'" *ngFor="let syllabus_subtitle of syllabus.subTitle; let i=index">
                                                                <div class="v-stepper">
                                                                    <div class="circle"></div>
                                                                    <div class="line"></div>
                                                                </div>
                                                                <div class="content">{{syllabus_subtitle.title}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                            <div class="corses-singel-left">
                                <a href="#" target="_blank">
                                    <div class="instructor-cont">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <div class="corses-singel-image">
                                                        <img src="assets/images/course/cu-1.jpg" alt="Courses">
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="instructor-description">
                                                        <div class="instructor-author">
                                                            <div class="author-name">
                                                                <h5>RPA ‘Automation Anywhere’ - Offline</h5>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing
                                                                    elit. Delectus fuga ratione molestiae.</p>
                                                                <span><b class="text-dark">Completed on -</b>
                                                                    12/12/2020</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <hr>
                                <a href="#" target="_blank">
                                    <div class="instructor-cont">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <div class="corses-singel-image">
                                                        <img src="assets/images/course/cu-1.jpg" alt="Courses">
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="instructor-description">
                                                        <div class="instructor-author">
                                                            <div class="author-name">
                                                                <h5>RPA ‘Automation Anywhere’ - Offline</h5>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing
                                                                    elit. Delectus fuga ratione molestiae.</p>
                                                                <span><b class="text-dark">Completed on -</b>
                                                                    12/12/2020</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>