<section id="page-banner" class="pt-105 pb-110" data-overlay="8" style="background-image: url(assets/images/services/serviceBnr.png); background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>Application Development</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="about-page" class="pt-70 pb-110">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h5>Smart Applications!</h5>
                </div>
                <div class="about-cont">
                    <p>With the help of our highly qualified and skilled IT professionals, we have been able to serve our clients with valuable Web Application Development & Mobile Application Services. Our web & mobile application development and custom
                        programming services include everything from a simple Content Management System enabled website to the most complex web-based internet applications, e-commerce portals, and social networking development services.</p>
                </div>
            </div>
        </div>
        <div class="about-items">
            <div class="row justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <span>01</span>
                        <h4>Web App's</h4>
                        <p>We provide progressive and innovation driven websites that helps you in building a great communication with your customers by displaying your best products.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/services/web-dev.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row flex-sm-row-reverse justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <span>02</span>
                        <h4>Mobile App's</h4>
                        <p>We design and develop awesome user friendly mobile applications by our passionately driven designers and developers. To excel in your business we help you elevate your customers count with our best work procedure.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/services/mobapp.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <span>03</span>
                        <h4>Ecommerce</h4>
                        <p>We help you in creating your website and online store, additionally manage social media and email marketing, all in one spot. You will be seen on all social media platforms thereby providing highest possible visibility contributing
                            to your customer base and profitability.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/services/ecommerce.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row flex-sm-row-reverse justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <span>04</span>
                        <h4>Payment Integration</h4>
                        <p>A payment gateway processes credit cards at online and offline stores. It transfers the key information between websites/mobile devices and payment processors/banks, and vice versa. So, if you decide to integrate payment into a
                            website and make the user experience smooth, we are here to help you.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/services/payments.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <span>05</span>
                        <h4>UI / UX Design</h4>
                        <p>The primary goal of any business is to increase its sales and increase the growth of the business. UX/UI Design plays an essential role in achieving this goal. This is where we have ample experience and talent.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/services/ui-ux-design.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row flex-sm-row-reverse justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <span>06</span>
                        <h4>Digital Marketing</h4>
                        <p>Paid content in front of random social media users isn’t going to immediately work or perform well at all. You have to be strategic with your ad spend, content selection, targeting frequency and audience demographics. SkillEnableHr
                            is here to help ease the pain of fading organic reach with the best step-by-step action items to get your strategy not only working, but well-tuned.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/services/digitalMarketing.png" alt="servicePage">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>