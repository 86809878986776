import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-featured-project',
  templateUrl: './featured-project.component.html',
  styleUrls: ['./featured-project.component.css']
})
export class FeaturedProjectComponent implements OnInit {
  @Input() data: any[];
  @Input() arrayIndex: any;
  @Input() title: string;
  @Input() tuitor: string;
  @Input() details: string;
  @Input() language: string;
  @Input() cardImage: any;
  @Input() reviewCount: number;
  @Input() studentsCount: number;
  @Input() favouriteCount: number;
  @Input() courseId:any;

  // productId: number;
  // productName: string;
  // productPrice: number;
  // productDesc: string;

  arrayOne(n: number): any[] {
    return Array(n);
  }

  constructor() {}

  ngOnInit(): void {

  }

}
