<section id="slider-part" class="slider-active">
    <div *ngFor="let item of data; let i=index">
        <div class="single-slider bg_cover pt-150" style="background-image: url({{item.bgImage}})" data-overlay="4">
            <div class="container">
                <div class="row">
                    <div class="col-xl-7 col-lg-9">
                        <div class="slider-cont">
                            <h1 data-animation="bounceInLeft" data-delay="1s">{{item.title}}</h1>
                            <p data-animation="fadeInUp" data-delay="1.3s">{{item.details}}</p>
                            <ul>
                                <li><a data-animation="fadeInUp" data-delay="1.6s" class="main-btn" href="{{item.readmoreLink}}">Read More</a></li>
                                <li><a data-animation="fadeInUp" data-delay="1.9s" class="main-btn main-btn-2" href="{{item.getStartedLink}}">Get Started</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>