import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import videojs from 'video.js';
import { Router, ActivatedRoute } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

export interface Parent{
  course_title:string;
  trainer_title:string;
  id?: string;
}
@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CourseDetailsComponent implements OnInit {
  player: videojs.Player;
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };
  id: any;
  servicedate: Observable<Parent[]>;
  loggedIn = false;

  courseDetails: any;
  safeSrc: SafeResourceUrl;
  public innerWidth: any;

  constructor(private afs: AngularFirestore, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.getSingleService(this.id).then(data => {
          data.subscribe(singleservice => {
            console.log(singleservice);
            this.courseDetails = singleservice;
            if(this.courseDetails['service_video'] != ''){
              this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.courseDetails['service_video'] +"? enablejsapi=1&modestbranding=1&autohide=1&showinfo=0&rel=0&amp");
            }
          });
        });
      }
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    const service: AngularFirestoreCollection<Parent> = this.afs.collection<Parent>('TrainerCourseAssignMaster', res => {
      return res.where("course_title", "==", this.id).where("status", "==", 'upcoming').orderBy('createdAt', 'desc');
    });
    this.servicedate = service.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Parent;
          data.id = a.payload.doc.id;
          console.log(data)
          return data
        });
      });

  }

  async getSingleService(id) {
    const get = await this.afs.doc(`CourseNameMaster/${id}`);
    const getdata = await get.valueChanges();
    return getdata;
  }

  navTo(data, id){
    console.log(id, data);
    // if(this.loggedIn){
      localStorage.setItem('isPaymentFlow', 'true');
      localStorage.setItem('courseDetail', id);
      this.router.navigate(['/paymentGateway']);
    // } else {
      // this.router.navigate(['/signIn']);
    // }
  }

}
