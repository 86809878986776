<section id="page-banner" class="pt-105 pb-110" data-overlay="8" style="background-image: url(assets/images/services/serviceBnr.png); background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>Blog</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="errorMsg">
    <h1>Comming <span>Soon</span></h1>
    <img class="imgRotate" src="assets/images/recruit/design.png" alt="recruit" />
</section>
<!-- <section id="event-page" class="pt-90 pb-120 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6" *ngFor="let blog of blogList">
                <a href="/BlogDetail">
                    <app-single-blog title='{{blog.title}}' desc='{{blog.desc}}' image='{{blog.image}}' date='{{blog.date}}' time='{{blog.time}}'></app-single-blog>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <nav class="courses-pagination mt-50">
                    <ul class="pagination justify-content-center">
                        <li class="page-item">
                            <a href="#" aria-label="Previous">
                                <i class="fa fa-angle-left"></i>
                            </a>
                        </li>
                        <li class="page-item"><a class="active" href="#">1</a></li>
                        <li class="page-item"><a href="#">2</a></li>
                        <li class="page-item"><a href="#">3</a></li>
                        <li class="page-item">
                            <a href="#" aria-label="Next">
                                <i class="fa fa-angle-right"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</section> -->