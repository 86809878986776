import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
//firebase 
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from 'angularfire2/auth';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { CoursesComponent } from './components/courses/courses.component';
import { ContactComponent } from './components/contact/contact.component';
import { FeaturedCoursesComponent } from './components/featured-courses/featured-courses.component';
import { HttpClientModule } from '@angular/common/http';
import { DummydataService } from './dummydata.service';
import { CourseDetailsComponent } from './components/course-details/course-details.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { CourseStatusComponent } from './components/course-status/course-status.component';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { LatestFeedComponent } from './components/latest-feed/latest-feed.component';
import { SliderComponent } from './components/slider/slider.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
// import { environment } from '../src/environments/environment';

import { WindowRefService } from './window-ref.service';
import { AuthService } from './service/auth.service';
import { LoginGuard } from './service/login.guard';
import { MyCoursesComponent } from './components/my-courses/my-courses.component';
import { environment } from '../environments/environment';
import { ProfileComponent } from './components/profile/profile.component';
import { ApiService } from '../api/api.service';
import { AboutComponent } from './about/about.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalpopupComponent } from './components/modalpopup/modalpopup.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { ToastrModule } from 'ngx-toastr';
import { ServicePageComponent } from './components/service-page/service-page.component';
import { FeaturedProjectComponent } from './components/featured-project/featured-project.component';
import { RecruitmentPageComponent } from './components/recruitment-page/recruitment-page.component';
import { WorkDayPageComponent } from './components/work-day-page/work-day-page.component';
import { BlogPageComponent } from './components/blog-page/blog-page.component';
import { SingleBlogComponent } from './components/single-blog/single-blog.component';
import { BlogDetailPageComponent } from './components/blog-detail-page/blog-detail-page.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    CoursesComponent,
    ContactComponent,
    FeaturedCoursesComponent,
    CourseDetailsComponent,
    SignInComponent,
    SignUpComponent,
    CourseStatusComponent,
    PaymentGatewayComponent,
    TestimonialComponent,
    ProfileCardComponent,
    LatestFeedComponent,
    SliderComponent,
    RegisterFormComponent,
    MyCoursesComponent,
    ProfileComponent,
    AboutComponent,
    ModalpopupComponent,
    ServicePageComponent,
    FeaturedProjectComponent,
    RecruitmentPageComponent,
    WorkDayPageComponent,
    BlogPageComponent,
    SingleBlogComponent,
    BlogDetailPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    YouTubePlayerModule,  
    NgxSpinnerModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
  ],
  providers: [
    DummydataService, 
    AuthService, 
    LoginGuard, 
    WindowRefService, 
    ApiService 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
