import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map'

export interface Parent{
  course_title:string;
  trainer_title:string;
  id?: string;
}

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})

export class CoursesComponent implements OnInit {

  servicedate: Observable<Parent[]>;

  coursesList = [
    {
    "title": 'RPA ‘Automation Anywhere’',
    "tuitor": 'Abdhul Kalam',
    "cardImage": 'assets/images/course/cu-1.jpg',
    "reviewCount": 10,
    "studentsCount" : 20,
    "favouriteCount" : 20,
    },
    {
    "title": 'Angular 10 Upgraded',
    "tuitor": 'Karthick',
    "cardImage": 'assets/images/course/cu-2.jpg',
    "reviewCount": 20,
    "studentsCount" : 30,
    "favouriteCount" : 40,
    },
    {
      "title": 'Automation Anywhere',
      "tuitor": 'Raj Kumar',
      "cardImage": 'assets/images/course/cu-3.jpg',
      "reviewCount": 25,
      "studentsCount" : 45,
      "favouriteCount" : 10,
    },
    {
      "title": 'RPA ‘Automation Anywhere’',
      "tuitor": 'Abdhul Kalam',
      "cardImage": 'assets/images/course/cu-4.jpg',
      "reviewCount": 10,
      "studentsCount" : 20,
      "favouriteCount" : 20,
    },
    {
      "title": 'Angular 10 Upgraded',
      "tuitor": 'Karthick',
      "cardImage": 'assets/images/course/cu-5.jpg',
      "reviewCount": 20,
      "studentsCount" : 30,
      "favouriteCount" : 40,
    },
    {
      "title": 'Automation Anywhere',
      "tuitor": 'Raj Kumar',
      "cardImage": 'assets/images/course/cu-3.jpg',
      "reviewCount": 25,
      "studentsCount" : 45,
      "favouriteCount" : 10,
    },
  ];
  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    const service: AngularFirestoreCollection<Parent> = this.afs.collection<Parent>('CourseNameMaster', res => {
      return res.orderBy('createdAt', 'asc');
    });
    this.servicedate = service.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Parent;
          data.id = a.payload.doc.id;
          console.log(data);
          return data
        });
      });
  }

}
