import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-status',
  templateUrl: './course-status.component.html',
  styleUrls: ['./course-status.component.css']
})
export class CourseStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
