import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  constructor(private FormBuilder: FormBuilder, private auth: AuthService) { }

  ngOnInit() {
    this.loginForm = this.FormBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      message: [''],
    })
  }
  onSubmit(valid, value) {
    let mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (valid) {
      if (mailformat.test(value['email']) == false) {
        alert("Error: You have entered an invalid email address!");
        return false;
      } else if (value['name'] == '') {
        alert("Error: Please enter name");
        return false;
      }
      else if (value['phone'].length < 10 || value['phone'].length > 10) {
        alert("Error: Mobile Number must contain 10 digits!");
        return false;
      }
      else if (isNaN(Number(value['phone']))) {
        alert("Error: Mobile Number must be number!");
        return false;
      } else {
        console.log(value);
        this.auth.sendMessage(value)
          .then(data => {
            this.loginForm.reset();
          },
            error => {
              alert(error.message)
            });
      }
      
    } else {
      alert("Error: Please check all details!");
    }
  }

}
