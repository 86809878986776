<section id="page-banner" class="pt-105 pb-110" data-overlay="8" style="background-image: url(assets/images/services/serviceBnr.png); background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>Workday Integration Services</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Workday Integration Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="about-page" class="pt-70 pb-110">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h5>Workday Integration Services</h5>
                </div>
                <div class="about-cont">
                    <p>We at <b>Skill EnableHR</b> provide best in class support for <b>Workday Integration Services</b> across functions, both in terms of Project Support (Fixed Price) and <b>Staff Augmentation Support</b> (Time and Material).</p>
                </div>
            </div>
        </div>
        <div class="about-items">
            <div class="row justify-content-center align-items-center pt-40">
                <div class="col-12">
                    <div class="about-singel-items mt-30">
                        <!-- <h4>WorkDay</h4> -->
                        <span>Fixed Price and T&M Support<img class="imgRotate" src="assets/images/recruit/design.png" alt="recruit" /></span>
                        <p>Skill EnableHR had built POOL of resources expertise in</p>
                    </div>
                </div>
            </div>
            <div class="row flex-sm-row-reverse justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <ul class="pt-25">
                            <li>
                                <p>Workday Integration Development and Support experience across Workday functions mainly HCM.</p>
                            </li>
                            <li>
                                <p>STUDIO integration experience and capable to design, develop and test complex studio integrations independently</p>
                            </li>
                            <li>
                                <p>Strong experience with Cloud Connectors, CCW, EIB, DT's, XML/ XSLT, PICOF, and Workday STUDIO custom integrations, Report Writer BIRT.</p>
                            </li>
                            <li>
                                <p>Good experience in Business Process Configuration, Security, ISSG/ISU.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/recruit/concept.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <ul class="pt-25">
                            <li>
                                <p>Will create detailed system integration specifications, field mapping, and system process designs.</p>
                            </li>
                            <li>
                                <p>Work directly with client to confirm requirements, consider design alternatives, facilitate discussions and ultimately drive requirements sign-off.</p>
                            </li>
                            <li>
                                <p>Will be able to help clients to resolve integration issues requiring a depth of expertise in the Workday solution.</p>
                            </li>
                            <li>
                                <p>Will Facilitate knowledge transfer and educate clients on developing and operating interfaces to and from a SaaS platform.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/recruit/whiteboard-animation.png" alt="workdayPage">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>