<section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(assets/images/page-banner-2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>About Us</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="about-page" class="pt-70 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title mt-10">
                    <h2>Welcome to Skill EnableHR
                        <img class="imgRotate" src="assets/images/recruit/design.png" alt="recruit" />
                    </h2>

                </div>
                <div class="about-cont">
                    <p>Skill enableHR is a No.1 Software Training Institute in Bangalore providing Quality and Affordable Training Services, Located Strategically at Hsr Layout 1st Stage. Our advance course offerings are practice & project oritented. Most of our audience are working professionals which further helps in industrial live-projects and job referrals. We take very special care of absolute beginners.</p>
                    <p>Training is readily available on advance courses but the best training in Bangalore can only be experienced at Skill enableHR . The reasons if you ask us are firstly, our trainers. We have experienced and passionate trainer available for you through-out our working hours. The support and training you get from them is leaps ahead of others. Secondly, the our advance training program itself is structured to be very in-depth and hands-on. It however starts from scratch and keeps the complete beginner audience also comfortable. Take a free demo class of our training course in Bangalore and be sure to be amazed.</p>
                </div>
            </div>
        </div>
        <div class="about-items pt-60">
            <div class="row justify-content-center">
                <div class="col-md-6 col-12">
                    <div class="about-singel-items mt-30">
                        <span>01</span>
                        <h4>Why Choose us</h4>
                        <p>
                            "Improve Quality, you automatically improve productivity"</p>
						<p class="text-right">--- W. Edwards Deming</p>
                        <p>We believe in Quality and we will ensure that we deliver Quality Service to our Quality Customers.
                        </p>
                        <!-- <ol class="pl-25" style="list-style-type:circle;">
                            <li>Courses designed, Created and delivered by Subject Matter Experts</li>
                            <li>Live Project training practical approach</li>
                            <li>24/7 Support</li>
                            <li>Get certified</li>
                            <li>Mock-up Interview Sessions</li>
                            <li>Job Assistance</li>
                        </ol> -->
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/recruit/illustrations-services.png" alt="aboutus" />
                    </div>
                </div>
            </div>
            <div class="row flex-sm-row-reverse justify-content-center">
                <div class="col-md-6 col-12">
                    <div class="about-singel-items mt-30">
                        <span>02</span>
                        <h4>Our Mission</h4>
                        <p>SKILL ENABLEHR’s mission is to be the global leader in providing outsourced business processes. We build quality partnerships and use our operational expertise to create value for our customers, employees, and shareholders.</p>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/recruit/info-graphics.png" alt="aboutus" />
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-12">
                    <div class="about-singel-items mt-30">
                        <span>03</span>
                        <h4>Our vision</h4>
                        <p>To provide high‐quality, cost‐effective information technology business solutions and professional consulting services in order to address the needs of our customers, all while delivering maximum profits to our Clients.</p>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/recruit/imagine.png" alt="aboutus" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="teachers-part" class="pt-0 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="section-title mt-50 pb-35">
                    <h5>Our SME's</h5>
                </div>
            </div>
        </div>
        <div class="about-items pt-0">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <h4>IT Hiring / Staffing / Recruitment</h4>
                        <p><b>Vinoth</b>, 10+ Years of rich experience in Human Resource (IT Recruitment) 
                            Provided complete Human Resources Solution for our clients to design, transform, and run scalable, cost effective human resource operations. Lead and own the Recruitment cycle by working closely with stakeholders, attracting and selecting appropriate talent, salary negotiations and onboarding. </p>
                        <!-- <p>Proven ability to source, select and secure top-notch candidates for multiple concurrent positions. Subject matter expert in the areas of talent attraction, assessment and selection at all levels by managing vacancy intake sessions with the hiring community to gain a detailed understanding of the role and candidate proﬁle to advice on sourcing strategy to fulﬁll demands.</p> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <h4>Workday Integration Service</h4>
                        <p><b>Kumar</b>, Workday Integration Consultant with over 7 Years of experience in Workday Eco-System. Good understanding of Integration including Web Services, SaaS, Workday Architecture and Business Process Framework. STUDIO integration experience and capable to design, develop and test complex studio integrations independently. Strong experience with Cloud Connectors, CCW, EIB, DT's, XML/ XSLT, PICOF, and Workday STUDIO custom integrations, Report Writer BIRT. Good experience in Business Process Configuration, Security, ISSG/ISU.</p>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <h4>Application Development</h4>
                        <p><b>Rajkumar P</b>, 15+ Years of experience (Web and Mobile Application  Management) in Client Relationship, Development and Delivery Management including managing Branding and Digital Enterprise Transformation programs.</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>