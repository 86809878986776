import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/switchMap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare var swal: any;
interface Admin {
    name: string;
    email: string;
    phone: number;
    role: string;
    status: boolean;
    createdAt?: string;
}

@Injectable()
export class AuthService {
    admindata: any;
    user: any;
    constructor(private afAuth: AngularFireAuth,
        private router: Router,
        private route: ActivatedRoute,
        private afs: AngularFirestore,
        public SpinnerService: NgxSpinnerService,
        private toastr: ToastrService) { }


    checkuser(path) {
        if (localStorage.getItem('studentMaster') != null && localStorage.getItem('studentMaster') != undefined) {
            const user = JSON.parse(localStorage.getItem('studentMaster'));
            console.log(user)
            this.user = user
            const url = path || this.router.url;
            if (!user) {
                this.router.navigate(['/signIn']);
            } else {
                if (url == '/signIn' || url == '/signUp') {
                    return true;
                } else {
                    return true;
                }
            }
        } else {
            this.router.navigate(['/signIn']);
        }
    }

    logout() {
        this.afAuth.auth.signOut().then(data => {
            localStorage.clear();
            window.location.href = '/';
            this.toastr.success('Logout', 'Logged Out Successfully!!!');
        });
    }

    async registerAdmin(user): Promise<any> {
        // this.SpinnerService.show(); 
        this.toastr.info('Registration', 'Please wait while register!!!');
        const register = await this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password);
        let value = {};
        value['id'] = register.user.uid;
        value['role'] = 'ST';
        value['createdAt'] = new Date().toString()
        value['approve'] = true;
        value['student_title'] = user['name'];
        value['student_email'] = user['email'];
        value['student_loc'] = user['loc'];
        value['password'] = user['password'];
        value['student_mob'] = user['phone'];
        const create = await this.afs.collection('StudentMaster').doc(register.user.uid).set(value);
        const admin = await this.afs.doc(`StudentMaster/${register.user.uid}`);
        // this.SpinnerService.hide(); 
        admin.valueChanges().subscribe(data => {
            // console.log(data);
            // this.toastr.info('Registration', 'Please wait while register!!!'); 
            let value1 = {};
            value1['id'] = register.user.uid;
            value1['role'] = 'ST';
            value1['createdAt'] = new Date().toString()
            value1['approve'] = true;
            value1['student_title'] = user['name'];
            value1['student_email'] = user['email'];
            value1['student_loc'] = user['loc'];
            value1['student_mob'] = user['phone'];

            localStorage.setItem('studentMaster', JSON.stringify(value1));
            this.user = data;
            let roleurl = "";
            let flow = localStorage.getItem('isPaymentFlow')


            if (flow == 'true') {
                localStorage.removeItem('isPaymentFlow');
                roleurl = '/paymentGateway';
            } else {
                localStorage.removeItem('isPaymentFlow');
                roleurl = '/';
                window.location.href = '/';
            }
            this.router.navigate([roleurl])
        });
        return admin.valueChanges();
    }


    async resetAdmin(user): Promise<any> {
        this.afAuth.auth.sendPasswordResetEmail(user.email)
            .then(function () {
                this.toastr.info('Password Reset', 'Password Reset Email Sent!!!');
            }).catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // [START_EXCLUDE]
                if (errorCode == 'auth/invalid-email') {
                    alert(errorMessage);
                } else if (errorCode == 'auth/user-not-found') {
                    alert(errorMessage);
                }
                console.log(error);
            })
    }

    async sendMessage(user): Promise<any> {
        // console.log(user);
        var str = user.name;
        var str = str.toLowerCase();
        user.createdAt = new Date().toString()
        user.status = false;
        await this.afs.collection('messageMaster').add(user);
        this.toastr.success('Contact', 'Message Send Successfully!!!');
        return true;
    }

    async loginadmin(user): Promise<any> {
        // this.SpinnerService.show(); 
        this.toastr.info('Login', 'Please wait while login!!!');
        const login = await this.afAuth.auth.signInWithEmailAndPassword(user.email, user.password);
        console.log(login.user.uid);
        const admin = await this.afs.doc(`StudentMaster/${login.user.uid}`);
        // this.SpinnerService.hide();  
        admin.valueChanges().subscribe(data => {
            // console.log(data);
            // this.toastr.info('Login', 'Please wait while login!!!'); 
            if (data != undefined) {
                let value1 = {};
                value1['id'] = data['id'];
                value1['role'] = data['role'];
                value1['approve'] = true;
                value1['name'] = data['student_title'];
                value1['email'] = data['student_email'];
                value1['loc'] = data['student_loc'];
                value1['phone'] = data['student_mob'];

                localStorage.setItem('studentMaster', JSON.stringify(value1));
                this.user = data;
                let roleurl = "";
                let flow = localStorage.getItem('isPaymentFlow')

                if (flow == 'true') {
                    localStorage.removeItem('isPaymentFlow');
                    roleurl = '/paymentGateway';
                    this.router.navigate([roleurl])
                } else {
                    localStorage.removeItem('isPaymentFlow');
                    roleurl = '/';
                    window.location.href = '/';
                }
            } else {
                this.toastr.error('Error', 'Username or password might be wrong.');
            }

        });
        return admin.valueChanges();
    }

    async proceedToCheckout(data) {
        console.log(data);
        localStorage.removeItem('isPaymentFlow');
        let value = {};
        value['id'] = data.id;
        value['role'] = 'ST';
        value['createdAt'] = new Date().toString()
        value['approve'] = true;
        value['pid'] = data['payment_id'];
        value['student_title'] = data['name'];
        value['student_email'] = data['email'];
        value['student_mob'] = data['phone'];
        const create = await this.afs.collection('paymentMaster').doc(data.id).set(value);
        const admin = await this.afs.doc(`StudentMaster/${data.id}`);
        let coursedata = [];
        var count = 0;
        var counter = 0;
        await admin.valueChanges().subscribe(async rdata => {
            console.log(rdata);

            if (count == 0) {
                count++;
                coursedata = rdata['courseData'];
                if (coursedata != null && coursedata != undefined) {
                    coursedata.push(data['courseId']);
                } else {
                    coursedata = [];
                    coursedata.push(data['courseId']);
                }
            }

            // rdata['courseData'] = coursedata;
            localStorage.setItem('studentMaster', JSON.stringify(rdata));
            const document = await this.afs.doc('/StudentMaster/' + data.id);
            document.update({
                "courseData": coursedata
            });
            localStorage.removeItem('courseDetail');
            var roleurl = '/myCourses';
            this.router.navigate([roleurl])
            if (counter == 0) {
                this.toastr.success('Payment Success!', 'Course Enrolled Successfully!!!');
                counter++;
            }
            return true;
        });

    }

    showToast(_title, _text, _type, _buttonsStyling, _confirmButtonClass, _time) {
        swal({
            title: _title,
            text: _text,
            type: _type,
            showConfirmButton: false,
            timer: _time
        });
    }
}