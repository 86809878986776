import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-day-page',
  templateUrl: './work-day-page.component.html',
  styleUrls: ['./work-day-page.component.css']
})
export class WorkDayPageComponent implements OnInit {


  testimonialsList = [
    {
      "comments": 'A very interesting and valuable experience.  I learnt a lot and it gave me a good grounding in the basics. It really made me think, and I liked how it enabled me to interact wiht people through the practical work.',
      "name": 'Dr. Abdhul Kalam',
      "profileImage": 'assets/images/testimonial/t-1.jpg',
      "education": 'Bsc, Engineering',
    },
    {
      "comments": 'I found the course interesting, challenging, and rewarding. I learnt a lot by following the programme and the study ACS set out. Assignments were corrected quickly, with lots of positive feedback from the tutors.',
      "name": 'Karthick',
      "profileImage": 'assets/images/testimonial/t-2.jpg',
      "education": 'Bsc, Engineering',
    },
    {
      "comments": 'I have really improved my knowledge in this field.  It has also given me the confidence to start my own small business in private Careers Counselling.',
      "name": 'Raj Kumar',
      "profileImage": 'assets/images/testimonial/t-3.jpg',
      "education": 'Bsc, Engineering',
    },
  ];
  teachersList = [
    {
      "name": 'Karthick',
      "profileImage": 'assets/images/teachers/t-2.jpg',
      "position": 'Pro chencelor',
    },
    {
      "name": 'Raj Kumar',
      "profileImage": 'assets/images/teachers/t-5.jpg',
      "position": 'Pro chencelor',
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
