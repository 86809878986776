<section id="count-down-part" class="bg_cover pt-70 pb-120" data-overlay="8" style="background-image: url(assets/images/bg-2.jpg)">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6">
                <div class="count-down-cont pt-50">
                    <h3>FRESHERS with 100% Job Placement</h3>
                    <h2>Sign In</h2>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-md-8">
                <div class="category-form category-form-3 pt-50">
                    <div class="form-title text-center">
                        <span *ngIf="subTitle != null">{{subTitle}}</span>
                    </div>
                    <div class="main-form">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.valid,loginForm.value)">
                            <div class="singel-form">
                                <input type="email" placeholder="Email id" type="email" formControlName="email" [ngClass]="{ 'is-invalid': f.email.errors }">
                                <div *ngIf="f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="singel-form">
                                <input type="text" placeholder="Password"  formControlName="password" type="password" [ngClass]="{ 'is-invalid': f.password.errors }">
                                <div *ngIf="f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                            <div class="singel-form">
                                <a class="w-100">
                                    <button class="main-btn" type="submit">Sign In</button>
                                </a>
                            </div>
                            <br>
                            <p class="float-right">Not have account? <span style="cursor:pointer" [routerLink]="'/signUp'">Register
                                    Now</span></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div id="counter-part" class="bg_cover pt-25 pb-70 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="singel-counter counter-3 text-center mt-40">
                    <span><span class="counter">8,000</span>+</span>
                    <p>Students enrolled</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="singel-counter counter-3 text-center mt-40">
                    <span><span class="counter">20</span>+</span>
                    <p>Years</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="singel-counter counter-3 text-center mt-40">
                    <span><span class="counter">11,000</span>+</span>
                    <p>People certifie</p>
                </div>
            </div>
        </div>
    </div>
</div>