import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-latest-feed',
  templateUrl: './latest-feed.component.html',
  styleUrls: ['./latest-feed.component.css']
})
export class LatestFeedComponent implements OnInit {
  @Input() ddmmyyyy: string;
  @Input() title: string;
  // @Input() startTime: number;
  // @Input() endTime: number;
  // @Input() location: any;
  constructor() { }

  ngOnInit(): void {
  }

}
