<header id="header-part">
    <div class="header-top d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <marquee scrollamount="10" direction="left">Mainframe and RPA Blue Prism Training with <strong>100% Job Placement for FRESHERS</strong>, Contact immediately <strong>!!!</strong></marquee>
                </div>
                <!-- <div class="col-lg-6">
                    <div class="header-contact text-lg-left text-center">
                        <ul>
                            <li><img src="assets/images/all-icon/map.png" alt="icon"><span>127/5, Bangalore</span></li>
                            <li><img src="assets/images/all-icon/email.png" alt="icon"><span>test@gmail.com</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="header-opening-time text-lg-right text-center">
                        <p>Opening Hours : Monday to Sunday - 8 Am to 8 Pm</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="header-logo-support pt-10 pb-10">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="logo">
                        <a href="demo/edubin-education-html-template/index-2.html">
                            <img src="assets/images/logo.jpg" alt="Logo">
                        </a>
                        Skill EnableHR
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="support-button float-right d-none d-md-block">
                        <div class="support float-left">
                            <div class="icon">
                                <img src="assets/images/all-icon/support.png" alt="icon">
                            </div>
                            <div class="cont">
                                <p>Need Help? call us@</p>
                                <span>
                                    <a href="tel:+919620666883" target="_blank"><span>9620666883</span></a>
                                /
                                <a href="tel:+919620666944" target="_blank"><span> 9620666944</span></a>
                                </span>


                            </div>
                        </div>
                        <div class="button float-left">
                            <a [routerLink]="'/Contact'" routerLinkActive="active" class="main-btn">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="navigation">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 col-md-10 col-sm-9 col-8">
                    <nav class="navbar navbar-expand-lg">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <a href="/Home" routerLinkActive="active">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a href="/about" routerLinkActive="active">About Us</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="'/OurService'" routerLinkActive="active">Services</a>
                                    <ul class="sub-menu">
                                        <!-- <li><a [routerLink]="'/OurService'" routerLinkActive="active">Application Development</a></li> -->
                                        <li><a [routerLink]="'/Courses'" routerLinkActive="active">IT Training</a></li>
                                        <li><a [routerLink]="'/Recruitment'" routerLinkActive="active">IT Hiring/Staffing/Recruitment</a></li>
                                        <!-- <li><a [routerLink]="'/WorkDay'" routerLinkActive="active">Workday Integration Service</a></li> -->
                                    </ul>
                                </li>
                                <!-- <li class="nav-item">
                                    <a [routerLink]="'/Blog'" routerLinkActive="active">Blog</a>
                                </li> -->
                                <li class="nav-item" *ngIf='user?.role=="ST"'>
                                    <a [routerLink]="'/myCourses'" routerLinkActive="active">My Courses</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="'/Contact'" routerLinkActive="active">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-3 col-4">
                    <div class="right-icon text-right">
                        <ul>
                            <!-- <li><a href="#" id="search"><i class="fa fa-search"></i></a></li> -->
                            <!-- <li><a [routerLink]="'/signIn'" id="search"><i class="fa fa-user"></i></a></li> -->
                            <li>
                                <div class="dropdown">
                                    <a *ngIf='user?.role=="ST"' id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Hi {{user?.student_title != null ? user?.student_title : user?.name}}, 
                                        <i class="fa fa-user"></i>
                                    </a>
                                    <a *ngIf='user?.role!="ST"' id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-user"></i> Login
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                                        <div *ngIf='user?.role=="ST"'>
                                            <a class="dropdown-item" [routerLink]="'/profile'"><i
                                                    class="fa fa-user"></i>
                                                &nbsp;Profile</a>
                                            <div class="dropdown-divider"></div>
                                            <a (click)="auth.logout()" class="dropdown-item"><i class="fa fa-lock"></i>
                                                &nbsp;Log out</a>
                                        </div>
                                        <div *ngIf='user?.role!="ST"'>
                                            <a class="dropdown-item" [routerLink]="'/signIn'"><i
                                                class="fa fa-sign-in"></i>
                                            &nbsp;Login</a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" [routerLink]="'/signUp'"><i
                                                class="fa fa-user-plus"></i>
                                            &nbsp;Register</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- <li><a href="#"><i class="fa fa-shopping-bag"></i><span>0</span></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>