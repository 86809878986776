import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profile = [
    {
      "name": 'Karthika',
      "profileImage": 'assets/images/teachers/t-3.jpg',
      "position": 'MBA - Logistics',
    },
  ];

  udata : any;

  constructor() { }

  ngOnInit(): void {
    console.log(JSON.parse(localStorage.getItem('studentMaster')));
    this.udata = JSON.parse(localStorage.getItem('studentMaster'))
  }

}
