import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modalpopup',
  templateUrl: './modalpopup.component.html',
  styleUrls: ['./modalpopup.component.css']
})
export class ModalpopupComponent implements OnInit {
  @Input() titleContent: string;
  @Input() bodyContent: string;

  constructor() { }

  ngOnInit(): void {
  }

}
