import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map'
import { ApiService } from 'src/api/api.service';

export interface Parent {
  course_title: string;
  trainer_title: string;
  id?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  sliderData = [
    {
      "title": 'About Us',
      "bgImage": 'assets/images/page-banner-3.jpg',
      "details": 'Skill EnableHR to be read as Skill enabler founded by Software Professional, who has 20+ years of IT industry Experience with motive to Provide high quality, cost effective information technology business solutions.',
      "readmoreLink": '#',
      "getStartedLink": 'about',
    },
    {
      "title": 'IT Hiring/Staffing/Recruitment',
      "bgImage": 'assets/images/service.jpg',
      "details": 'Acquiring the right talent is the only key for Organisation growth; we believe in it and ensure that spending good amount of time in finding right talents for our Customers.',
      "readmoreLink": '#',
      "getStartedLink": 'Recruitment',
    },
    // {
    //   "title": 'Workday Integration Service',
    //   "bgImage": 'assets/images/bg-3.jpg',
    //   "details": 'Skill EnableHR had built POOL of resources expertise in Workday Integrations such as EIB, Core Connectors & Workday Studio along with the calculated fields and Advanced Custom Reports.',
    //   "readmoreLink": '#',
    //   "getStartedLink": 'WorkDay',
    // },
    // {
    //   "title": "Application Development",
    //   "bgImage": 'assets/images/servicebg.jpg',
    //   "details": 'Grow online with us, Moving businesses online - Made easy.',
    //   "readmoreLink": '#',
    //   "getStartedLink": 'OurService',
    // },
    // {
    //   "title": 'Digital Marketing',
    //   "bgImage": 'assets/images/digital.jpeg',
    //   "details": 'We are here to help ease the pain of fading organic reach with the best step-by-step action items to get your strategy not only working, but well-tuned.',
    //   "readmoreLink": '#',
    //   "getStartedLink": 'OurService',
    // },
    {
      "title": 'IT Training',
      "bgImage": 'assets/images/bg-1-min.jpg',
      "details": 'Mainframe and RPA Blue prism Training only for Freshers with 100% Job Placement',
      "readmoreLink": '#',
      "getStartedLink": 'Courses',
    }
    // {
    //   "title": 'Mainframe Training',
    //   "bgImage": 'assets/images/y-1.jpg',
    //   "details": 'Only for FRESHERS with 100% Job Placement',
    //   "readmoreLink": '#',
    //   "getStartedLink": 'Courses',
    // }
  ];

  testimonialsList = [
    {
      "comments": 'A very interesting and valuable experience.  I learnt a lot and it gave me a good grounding in the basics. It really made me think, and I liked how it enabled me to interact wiht people through the practical work.',
      "name": 'Dr. Abdhul Kalam',
      "profileImage": 'assets/images/testimonial/t-1.jpg',
      "education": 'Bsc, Engineering',
    },
    {
      "comments": 'I found the course interesting, challenging, and rewarding. I learnt a lot by following the programme and the study ACS set out. Assignments were corrected quickly, with lots of positive feedback from the tutors.',
      "name": 'Karthick',
      "profileImage": 'assets/images/testimonial/t-2.jpg',
      "education": 'Bsc, Engineering',
    },
    {
      "comments": 'I have really improved my knowledge in this field.  It has also given me the confidence to start my own small business in private Careers Counselling.',
      "name": 'Raj Kumar',
      "profileImage": 'assets/images/testimonial/t-3.jpg',
      "education": 'Bsc, Engineering',
    },
  ];
  teachersList = [
    {
      "name": 'Dr. Abdhul Kalam',
      "profileImage": 'assets/images/teachers/t-1.jpg',
      "position": 'Vice chencelor',
    },
    {
      "name": 'Karthick',
      "profileImage": 'assets/images/teachers/t-2.jpg',
      "position": 'Pro chencelor',
    },
    {
      "name": 'Raj Kumar',
      "profileImage": 'assets/images/teachers/t-5.jpg',
      "position": 'Pro chencelor',
    },
    {
      "name": 'Karthika',
      "profileImage": 'assets/images/teachers/t-3.jpg',
      "position": 'Aerobics head',
    },
  ];
  newsList = [
    {
      "ddmmyyyy": '2 December 2020',
      "title": 'Tech Summit',
      "startTime": '10:00 Am',
      "endTime": '03:00 Pm',
      "location": 'Rc Auditorim',
    },
    {
      "ddmmyyyy": '3 December 2020',
      "title": 'Enviroement conference',
      "startTime": '12:00 Am',
      "endTime": '05:00 Pm',
      "location": 'Rc Auditorim',
    },
    {
      "ddmmyyyy": '4 December 2020',
      "title": 'Tech Summit',
      "startTime": '09:00 Am',
      "endTime": '02:00 Pm',
      "location": 'Rc Auditorim',
    },
  ];

  servicedate:any = [];
  coursedate:any = [];

  projects = [
    {
      "id":'1',
      "title": 'Project 1',
      "tuitor": 'Abdhul Kalam',
      "details":'One of the best Web Application development done by our wonderful team experts.',
      "language":'Angular10, Firebase, MySQL',
      "cardImage": 'assets/images/projects/project1.jpg',
      "reviewCount": 10,
      "studentsCount": 20,
      "favouriteCount": 20,
    },
    {
      "id":'2',
      "title": 'Project 2',
      "details":'One of the best Mobile Application development done by our wonderful team experts.',
      "language":'Angular, Firebase, MySQL, ionic',
      "tuitor": 'Karthick',
      "cardImage": 'assets/images/projects/project1.jpg',
      "reviewCount": 20,
      "studentsCount": 30,
      "favouriteCount": 40,
    },
    {
      "id":'3',
      "title": 'Project 3',
      "details":'One of the best Mobile Application development done by our wonderful team experts.',
      "language":'Angular, Firebase, MySQL, ionic',
      "tuitor": 'Raj Kumar',
      "cardImage": 'assets/images/projects/project1.jpg',
      "reviewCount": 25,
      "studentsCount": 45,
      "favouriteCount": 10,
    }
  ];

  coursesList = [
    {
      "title": 'RPA ‘Automation Anywhere’',
      "tuitor": 'Abdhul Kalam',
      "cardImage": 'assets/images/course/cu-1.jpg',
      "reviewCount": 10,
      "studentsCount": 20,
      "favouriteCount": 20,
    },
    {
      "title": 'Angular 10 Upgraded',
      "tuitor": 'Karthick',
      "cardImage": 'assets/images/course/cu-2.jpg',
      "reviewCount": 20,
      "studentsCount": 30,
      "favouriteCount": 40,
    },
    {
      "title": 'Automation Anywhere',
      "tuitor": 'Raj Kumar',
      "cardImage": 'assets/images/course/cu-3.jpg',
      "reviewCount": 25,
      "studentsCount": 45,
      "favouriteCount": 10,
    },
    {
      "title": 'RPA ‘Automation Anywhere’',
      "tuitor": 'Abdhul Kalam',
      "cardImage": 'assets/images/course/cu-4.jpg',
      "reviewCount": 10,
      "studentsCount": 20,
      "favouriteCount": 20,
    },
    {
      "title": 'Angular 10 Upgraded',
      "tuitor": 'Karthick',
      "cardImage": 'assets/images/course/cu-5.jpg',
      "reviewCount": 20,
      "studentsCount": 30,
      "favouriteCount": 40,
    },
    {
      "title": 'Automation Anywhere',
      "tuitor": 'Raj Kumar',
      "cardImage": 'assets/images/course/cu-3.jpg',
      "reviewCount": 25,
      "studentsCount": 45,
      "favouriteCount": 10,
    },
  ];
  constructor(private afs: AngularFirestore, public apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.getCourseDetails().subscribe(async data => {
      // await loading.dismiss();
      if (data['status'] == 200 || data['status'] == true) {
        console.log(data);
      }
    });
    const service: AngularFirestoreCollection<Parent> = this.afs.collection<Parent>('TrainerCourseAssignMaster', res => {
      return res.orderBy('createdAt', 'asc');
    });
    this.servicedate = service.snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Parent;
          data.id = a.payload.doc.id;
          return data
        });
      });

      const service1: AngularFirestoreCollection<Parent> = this.afs.collection<Parent>('CourseNameMaster', res => {
        return res.orderBy('createdAt', 'asc');
      });
      this.coursedate = service1.snapshotChanges()
        .map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Parent;
            data.id = a.payload.doc.id;
            return data
          });
        });
  }

}
