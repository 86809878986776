<section class="profile my-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-12">
                <img class="img-fluid" src="assets/images/staff.svg" alt="">
            </div>
            <div class="col-lg-9 col-sm-6 col-12 mt-2">
                <p><b>Name: </b> {{udata?.student_title != null ? udata?.student_title : udata?.name}}</p>
                <p><b>Position: </b> Student</p>
                <!-- <p><b>MemberShip: </b> <span class="text-success font-weight-bold">Premium</span></p> -->
                <p><b>Email Id: </b> <a href="mailto:{{udata.email}}">{{udata?.student_email != null ? udata?.student_email : udata?.email}}</a></p>
                <p><b>Phone #: </b> <a href="tel:{{udata.phone}}">{{udata?.student_mob != null ? udata?.student_mob : udata?.phone}}</a></p>
                <p><b>Location: </b> <a href="tel:{{udata.phone}}">{{udata?.student_loc != null ? udata?.student_loc : udata?.loc}}</a></p>
            </div>
        </div>
    </div>
</section>
