<div class="singel-event-list mt-30">
    <div class="event-thum">
        <img src="{{image}}" alt="Event">
    </div>
    <div class="event-cont">
        <span><i class="fa fa-calendar"></i> {{date}}</span>
        <h4>{{title}}</h4>
        <span><i class="fa fa-clock-o"></i> {{time}}</span>
        <!-- <span><i class="fa fa-map-marker"></i> {{date}}</span> -->
        <p>{{desc}}</p>
    </div>
</div>