<section id="count-down-part" class="bg_cover pt-70 pb-120" data-overlay="8"
    style="background-image: url(assets/images/bg-2.jpg)">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6">
                <div class="count-down-cont pt-50">
                    <!-- <h3>Get 100s of online Courses For 50%</h3> -->
                    <h2>Pay Now</h2>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-md-8">
                <div class="category-form category-form-3 pt-50">
                    <div class="form-title text-center">
                        <span>Payment Gateway</span>
                    </div>
                    <div class="main-form">
                        <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit(subscribeForm.valid,subscribeForm.value)">
                            <div class="singel-form">
                                <input type="text" formControlName="name" placeholder="Your Name" required>
                            </div>
                            <div class="singel-form">
                                <input type="email" formControlName="email" placeholder="Your Mail" required>
                            </div>
                            <div class="singel-form">
                                <input type="text" formControlName="phone" placeholder="Your Phone" required>
                            </div>
                            <div class="singel-form">
                                <a class="w-100">
                                    <button class="main-btn" type="submit">Pay Now</button>
                                </a>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>