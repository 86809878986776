import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { CoursesComponent } from './components/courses/courses.component';
import { CourseDetailsComponent } from './components/course-details/course-details.component';
import { ContactComponent } from './components/contact/contact.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CourseStatusComponent } from './components/course-status/course-status.component';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
import { LoginGuard } from './service/login.guard';
import { MyCoursesComponent } from './components/my-courses/my-courses.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AboutComponent } from './about/about.component';
import { ServicePageComponent } from './components/service-page/service-page.component';
import { RecruitmentPageComponent } from './components/recruitment-page/recruitment-page.component';
import { WorkDayPageComponent } from './components/work-day-page/work-day-page.component';
import { BlogPageComponent } from './components/blog-page/blog-page.component';
import { BlogDetailPageComponent } from './components/blog-detail-page/blog-detail-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'full'},
  { path: 'Home', component: HomeComponent },
  { path: 'Home/CourseDetails/:id', component: CourseDetailsComponent },
  { path: 'Courses', component: CoursesComponent },
  { path: 'about', component: AboutComponent },
  { path: 'Courses/CourseDetails/:id', component: CourseDetailsComponent },
  { path: 'signUp', component: SignUpComponent },
  { path: 'signIn', component: SignInComponent },
  { path: 'Courses/CourseStatus', component: CourseStatusComponent },
  { path: 'paymentGateway', component: PaymentGatewayComponent, canActivate:[LoginGuard] },
  { path: 'Contact', component: ContactComponent },
  { path: 'myCourses', component: MyCoursesComponent, canActivate:[LoginGuard] },
  { path: 'profile', component: ProfileComponent, canActivate:[LoginGuard] },
  { path: 'OurService', component: ServicePageComponent },
  { path: 'Recruitment', component: RecruitmentPageComponent },
  { path: 'WorkDay', component: WorkDayPageComponent },
  { path: 'Blog', component: BlogPageComponent },
  { path: 'BlogDetail', component: BlogDetailPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
