<section id="page-banner" class="pt-105 pb-110" data-overlay="8" style="background-image: url(assets/images/services/serviceBnr.png); background-position: center;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>IT Hiring/Staffing/Recruitment</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Services</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Recruitment</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="about-page" class="pt-70 pb-110">
    <div class="container">
        <div class="about-items">
            <div class="row justify-content-center align-items-center pt-40">
                <div class="col-12">
                    <div class="about-singel-items mt-30">
                        <span class="pr-30">Talent Acquisition</span>
                        <img class="imgRotate" src="assets/images/recruit/design.png" alt="recruit" />
                        <h4>1000+ recruitment</h4>
                        <p><b>Skill Enable-HR</b> is a global provider of talent acquisition and staff augmentation with focus on helping our customers identify, hire and engage top talent. We understand more than just recruitment – <b>We understand our clients’ business</b>.</p>
                        <p>We deliver on more than 1000+ recruitment mandates each year. Our recruitment team is comprised of 25 top-notch recruitment professionals who have in-depth domain knowledge &widest candidate network. We consistently demonstrate
                            to over 30+ clients a truly unique.</p>
                        <p class="pt-15">Our success is attributed to the outstanding quality of our people and expertise on varied roles and industries to search for the best fit candidate. We bring together our collective expertise on broad spectrum of industry verticals
                            to successfully delivery every single time.</p>
                    </div>
                </div>
            </div>
            <div class="row flex-sm-row-reverse justify-content-center align-items-center pt-40">
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <!-- <h4>Our Service's</h4> -->
                        <p>We offer a truly top-class capability to deliver talent solutions with true business impact. Our solutions offer enterprise class experience and wide global reach to identify, attract top talent successful business outcome.</p>
                        <ul class="pt-25">
                            <li>
                                <p>SKILL ENABLEHR has a highly skilled set of talents that are built on referral and successful partnerships.</p>
                            </li>
                            <li>
                                <p>Our recruiters are exceptionally skilled in identifying top talent around the globe and matching them with the client’s needs.</p>
                            </li>
                            <li>
                                <p>We have an 85% fill ratio for every open position we partner for.</p>
                            </li>
                            <li>
                                <p>Contract renewal rate for our consultancy is 90%.</p>
                            </li>
                            <li>
                                <p>60% of our consultants are converted to full-time employees.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-sm-10">
                    <div class="about-singel-items mt-30">
                        <img class="img-fluid" src="assets/images/recruit/gather.png" alt="servicePage">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center pt-40">
                <div class="col-12">
                    <div class="corses-tab mt-30">
                        <ul class="nav nav-justified tabTitle" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Our Services</a>
                            </li>
                            <li class="nav-item">
                                <a id="curriculam-tab" data-toggle="tab" href="#curriculam" role="tab" aria-controls="curriculam" aria-selected="false">Technology Focus Areas</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                <div class="accordion" id="accordionExample">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span class="head">Temporary Staffing</span>
                                            </a>
                                        </div>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Temporary staffing is a great solution for those companies who need a flexible workforce. If your operations are seasonal or you take on short- term projects on a regular basis, temporary staffing can
                                                        allow you to operate at peak efficiency regardless of your workload. We maintain a deep roster of potential temporary employees who are pre-screened and ready to begin work on short notice. This
                                                        allows you to scale your staff number up when you need it without having unnecessary personnel costs when things slow down.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingTow">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseTow" aria-expanded="true" aria-controls="collapseTow">
                                                <span class="head">Temp-To-Hire</span>
                                            </a>
                                        </div>
                                        <div id="collapseTow" class="collapse" aria-labelledby="headingTow" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Many employers have spent dozens of hours screening, interviewing, and training a new employee, only to find that they’re not the right fit for the company once they’re hired. This can lead to problems
                                                        throughout your company as lack of productivity and poor morale build. SKILL ENABLEHR’s temp- to-hire services allow you to avoid the hassle associated with finding and screening a candidate, and
                                                        also give you a probationary period so that you can be sure your new employee is the right fit for your team. During this probationary period, SKILL ENABLEHR handles payroll for you, so that you
                                                        only have to complete internal onboarding processes when you’re sure you’ve found a perfect fit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <span class="head">Permanent Placement</span>
                                            </a>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>When you have an opening in apposition that’s mission-critical, it’s important that you find the right individual as quickly as possible. When you’re responsible for running your entire business, it
                                                        can be difficult to find the right person, and a quick decision can lead to the wrong hire. SKILL ENABLEHR is always on the lookout for outstanding candidates for sales, administration, management,
                                                        and technical fields. When you have an important opening to fill, we can immediately schedule interviews with candidates that we’ve personally screened and selected based on your requirements. This
                                                        allows you to keep your company fully staffed so that you can focus on your business.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="curriculam" role="tabpanel" aria-labelledby="curriculam-tab">
                                <div class="accordion" id="accordion2Example">
                                    <div class="card">
                                        <div class="card-header" id="heading1One">
                                            <a href="#" data-toggle="collapse" data-target="#collapse1One" aria-expanded="true" aria-controls="collapse1One">
                                                <span class="head">Microsoft Technologies</span>
                                            </a>
                                        </div>
                                        <div id="collapse1One" class="collapse show" aria-labelledby="heading1One" data-parent="#accordion2Example">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Net Technologies, MS SQL, Visual Studio, C / C++/VC++, COM DCOM,UML,HTML,XML,CSS , ASP,VB, Windows, Exchange server,D2K,Active X , Interbase, Interwoven, MQ Series , XSL/ XSLT, OSS / BSS ,Biztalk ,Sharepoint
                                                        server , Delphi , Lotus Notes ,MFC , OOPS ,PRO*C</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading2Tow">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapse2Tow" aria-expanded="true" aria-controls="collapse2Tow">
                                                <span class="head">Internet / Intranet / e- commerce</span>
                                            </a>
                                        </div>
                                        <div id="collapse2Tow" class="collapse" aria-labelledby="heading2Tow" data-parent="#accordion2Example">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Oracle Applications, Oracle, HRMS / Financials, PL/SQL, SQL, Oracle Database, Oracle gateway, Java, EJB, J2EE, JSP,Web logic,Web sphere, Struts, JBoss, Jrun, Tomcat, Servlet, Swing, Applet, JDBC, JDK,
                                                        Jbuilder, RMI, Apache, Iplanet, JMS, JNI, JRUN, JTA.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading3Three">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapse3Three" aria-expanded="false" aria-controls="collapse3Three">
                                                <span class="head">ERP / CRM</span>
                                            </a>
                                        </div>
                                        <div id="collapse3Three" class="collapse" aria-labelledby="heading3Three" data-parent="#accordion2Example">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Workday, SAP / ABAP, Peoplesoft, Broad vision, Vantive, Clarify, MFG PRO, Siebel, Seebeyond, Tibco, Mercator, Interwoven.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFour">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <span class="head">Data Warehousing & BI</span>
                                            </a>
                                        </div>
                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion2Example">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Informatica - Power center, Abinitio, Datastage, Power analyzer, Business Objects ETL tools, Cognos, Erwin, Data Mining, Data Modeling, Informix Teradata.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFive">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <span class="head">Quality Assurance</span>
                                            </a>
                                        </div>
                                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion2Example">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Six Sigma, CMM, Testing, Winrunner, Load Runner, Astra, SAP Basis Black Box, White Box, Rational robot. Clear case, Clear quest, Functional testing, QA RUN, QTP, Silk Test, SQA Test.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingSix">
                                            <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                <span class="head">Telecom Domain</span>
                                            </a>
                                        </div>
                                        <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion2Example">
                                            <div class="card-body">
                                                <div class="container-fluid">
                                                    <p>Vxworks, psos, Kernel programming, Device Driver programming, Linux / Unix internals, Shell programming - Scripts - KORN, TCL, PERL, Expect, ARM/MVC architecture: Internet / ATMATL, ATM, Digital Signaling,
                                                        ISDN QSIG, TCP/IP, DSL technology, DTS, DSLAM, Ethernet drivers, GDI, HTTP,STL, TCL /TK: Mobile Telephony / - CDMA, GSM, GPRS, UMTS, IVR (interactive voice response), EPBAX, PBX.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>