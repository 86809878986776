import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';

import { Observable } from 'rxjs/Observable';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';

declare var $: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  subTitle = "Sign In";

  constructor(private FormBuilder: FormBuilder, private auth: AuthService) { }

  ngOnInit() {
    this.loginForm = this.FormBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
  }

  get f() { return this.loginForm.controls; }

  onSubmit(valid, data) {
    if (valid) {
      var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      let re = /[0-9]/;
      let re1 = /[a-z]/;
      let re2 = /[A-Z]/;
      if (mailformat.test(data['email']) == false) {
        alert("Error: You have entered an invalid email address!");
        return false;
      } else if (data['password'].length < 6) {
        alert("Error: Password must contain at least six characters!");
        return false;
      }
      else if (!re.test(data['password'])) {
        alert("Error: password must contain at least one number (0-9)!");
        return false;
      }
      else if (!re1.test(data['password'])) {
        alert("Error: password must contain at least one lowercase letter (a-z)!");
        return false;
      }
      else if (!re2.test(data['password'])) {
        alert("Error: password must contain at least one uppercase letter (A-Z)!");
        return false;
      } else {
        
        this.auth.loginadmin(data)
          .then(data => {
            console.log(data)
          },
            error => {
              alert(error.message)
            });
      }
    } else {
      alert("Error: Please check email and password!");
    }
  }

}
