<section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(assets/images/page-banner-2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>RPA ‘Automation Anywhere’</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Courses</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Learn RPA ‘Automation Anywhere’</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="corses-singel" class="pt-90 pb-120 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="corses-singel-left mt-20">
                    <div class="corses-tab">
                        <div class="curriculam-cont">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <!-- <span class="lecture"><i class="fa fa-file-o"></i>Lecture 1.1</span> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>Introduction to Automation Anywhere</span></li>
                                                <li>
                                                    <!-- <span class="time d-none d-md-block">
                                                        <i class="fa fa-clock-o"></i> 
                                                        <span> 00.30.00</span>
                                                    </span> -->
                                                    <h5 class="time d-none d-md-block theme_primary">Completed</h5>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTow">
                                        <a href="#" data-toggle="collapse" data-target="#collapseTow" aria-expanded="true" aria-controls="collapseTow">
                                            <!-- <span class="lecture"><i class="fa fa-file-o"></i>Lecture 1.2</span> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>Introduction to Enterprise Web Control Room</span></li>
                                                <li>
                                                    <!-- <span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span> -->
                                                    <h5 class="time d-none d-md-block theme_secondary">Under Progress</h5>
                                                </li>
                                            </ul>

                                        </a>
                                    </div>
                                    <div id="collapseTow" class="collapse show" aria-labelledby="headingTow" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step active">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <!-- <div class="lecture"><i class="fa fa-file-o"></i>Lecture 1.3</div> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>Automation Anywhere client Variables and Commands</span></li>
                                                <li><span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step active">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFore">
                                        <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseFore" aria-expanded="false" aria-controls="collapseFore">
                                            <!-- <span class="lecture"><i class="fa fa-file-o"></i>Lecture 1.4</span> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>MetaBots</span></li>
                                                <li><span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <div id="collapseFore" class="collapse" aria-labelledby="headingFore" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step active">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            <!-- <span class="lecture"><i class="fa fa-file-o"></i>Lecture 1.5</span> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>Advanced Features of Automation Anywhere</span></li>
                                                <li><span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step active">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingSix">
                                        <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            <!-- <span class="lecture"><i class="fa fa-file-o"></i>Lecture 1.6</span> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>Features of Enterprise Web Control Room</span></li>
                                                <li><span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step active">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingSeven">
                                        <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <!-- <span class="lecture"><i class="fa fa-file-o"></i>Lecture 1.7</span> -->
                                            <ul>
                                                <li><span class="head"><i class="fa fa-file-o"></i>IQ Bots (Self-paced)</span></li>
                                                <li><span class="time d-none d-md-block"><i class="fa fa-clock-o"></i> <span> 00.30.00</span></span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="step completed">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What is Automation Anywhere</div>
                                                </div>
                                                <div class="step active">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Benefits</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Set up of Automation Anywhere</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere products</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">What are Bots?</div>
                                                </div>

                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere architecture</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Types of Bots</div>
                                                </div>
                                                <div class="step">
                                                    <div class="v-stepper">
                                                        <div class="circle"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="content">Automation Anywhere Client Features</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>