<div class="category-form category-form-3 pt-50">
    <div class="form-title text-center">
        <span *ngIf="subTitle != null">{{subTitle}}</span>
    </div>
    <div class="main-form">
        <form>
            <div class="singel-form">
                <input type="email" placeholder="Email id" type="email">
            </div>
            <div class="singel-form">
                <input type="text" placeholder="Password" type="password">
            </div>
            <div class="singel-form">
                <a class="w-100">
                    <button class="main-btn" type="button">Sign In</button>
                </a>
            </div>
            <br>
            <p class="float-right">Not have account? <span style="cursor:pointer" [routerLink]="'/signUp'">Register
                    Now</span></p>
        </form>
    </div>
</div>