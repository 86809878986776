<section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(assets/images/page-banner-2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>{{courseDetails?.service_title}}</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">IT Training</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Learn {{courseDetails?.service_title}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="corses-singel" class="pt-50 pb-50 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="corses-singel-left mt-30">
                    <div class="title">
                        <h3>Learn {{courseDetails?.service_title}}</h3>
                    </div>
                    <!-- <div class="course-terms">
                        <ul>
                            <li>
                                <div class="teacher-name">
                                    <div class="thum">
                                        <video #target class="video-js vjs-default-skin" controls autoplay width="640" height="264" data-setup='{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "https://www.youtube.com/embed/c9F5kMUfFKk"}] }'>
                                        </video>
                                       
                                        <img src="assets/images/course/teacher/t-1.jpg" alt="Teacher">
                                        <video #target class="video-js" controls muted playsinline preload="none" src="https://www.youtube.com/watch?v=klGHKTsryfs"></video>

                                        <video #target class="video-js" controls muted playsinline preload="none"
                                        data-setup='{ "techOrder": ["html5", "youtube"] }'>
                                            <source src="https://www.youtube.com/watch?v=klGHKTsryfs" type="video/youtube">
                                          </video> 
                                    </div>
                                    <div class="name">
                                        <span>Teacher</span>
                                        <h6>{{courseDetails?.trainer_name}}</h6>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="course-category">
                                    <span>Category</span>
                                    <h6>Programaming </h6>
                                </div>
                            </li>
                            <li>
                                <div class="review">
                                    <span>Review</span>
                                    <ul>
                                        <li><a href="#"><i class="fa fa-star"></i></a></li>
                                        <li><a href="#"><i class="fa fa-star"></i></a></li>
                                        <li><a href="#"><i class="fa fa-star"></i></a></li>
                                        <li><a href="#"><i class="fa fa-star"></i></a></li>
                                        <li><a href="#"><i class="fa fa-star"></i></a></li>
                                        <li class="rating">(20 Reviws)</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                    <div class="corses-singel-image pt-50">
                        <!-- <iframe [class.thumbnail]=courseDetails?.course_image [src]="safeSrc" width="100%"
                            height="400px" frameborder="0"></iframe> -->
                        <img src="{{courseDetails?.service_image}}" alt="Courses">
                    </div>
                    <div class="corses-tab mt-30">
                        <ul class="nav nav-justified" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
                            </li>
                            <li class="nav-item">
                                <a id="curriculam-tab" data-toggle="tab" href="#curriculam" role="tab" aria-controls="curriculam" aria-selected="false">Curriculam</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a id="instructor-tab" data-toggle="tab" href="#instructor" role="tab"
                                    aria-controls="instructor" aria-selected="false">Instructor</a>
                            </li> -->
                            <!-- <li class="nav-item">
                                <a id="reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews"
                                    aria-selected="false">Demo Video</a>
                            </li> -->
                            <li class="nav-item">
                                <a id="timing-tab" data-toggle="tab" href="#timing" role="tab" aria-controls="timing" aria-selected="false">Timing</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                <div class="overview-description">
                                    <div class="singel-description pt-40">
                                        <h6>Course Summary</h6>
                                        <p class="text-ali">{{courseDetails?.service_desc}}</p>
                                    </div>
                                    <!-- <div class="singel-description pt-40">
                                        <h6>System Requirements</h6>
                                        <ul class="circleList">
                                            <li>We will be using Automation Anywhere Enterprise Edition for this course.
                                                You will get 60 Days access of the Enterprise Edition of the Tool.</li>
                                            <li>Operating Systems: Supports only Windows Operating System</li>
                                            <li>Processor speed: Recommended – Dual Core or higher</li>
                                            <li>RAM: 8 GB or higher RAM</li>
                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                            <div class="tab-pane fade" id="curriculam" role="tabpanel" aria-labelledby="curriculam-tab">
                                <div class="curriculam-cont">
                                    <div class="title">
                                        <h6>Learn {{courseDetails?.service_title}}</h6>
                                    </div>
                                    <div class="accordion" id="accordionExample">
                                        <div class="card" *ngFor="let syllabus of courseDetails?.service_detail; let i=index">
                                            <div class="card-header">
                                                <a href="#" data-toggle="collapse" class="collapsed" [attr.data-target]="'#collapse'+i" aria-expanded="true">

                                                    <ul>
                                                        <li><span class="head"><span class="lecture"><i
                                                                        class="fa fa-file-o"></i></span>{{syllabus.title}}</span>
                                                        </li>
                                                        <li><span class="time d-none d-md-block"></span></li>
                                                    </ul>
                                                </a>
                                            </div>
                                            <div [attr.id]="'collapse'+i" class="collapse" data-parent="#accordionExample" *ngIf="syllabus.subTitle.length > 0">
                                                <div class="card-body">
                                                    <ul class="circleList">
                                                        <li *ngFor="let syllabus_subtitle of syllabus.subTitle; let i=index">
                                                            {{syllabus_subtitle.title}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                                <div class="instructor-cont" *ngFor="let cdata of servicedate | async">
                                    <div class="instructor-author">
                                        <div class="author-thum">
                                            <img src="assets/images/user.svg" alt="Instructor"
                                                style="width:100px;">
                                        </div>
                                        <div class="author-name">
                                            <a>
                                                <h5>{{cdata?.trainer_name}}</h5>
                                            </a>
                                                <span>Desgination : Trainer</span>
                                                <br>
                                                <span>Skills : {{cdata?.trainer_skillset}}</span>
                                                <br>
                                                <span>Email : {{cdata?.trainer_email}}</span>
                                          </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                <div class="reviews-cont">
                                    <iframe *ngIf="safeSrc != ''" [class.thumbnail]=courseDetails?.course_image [src]="safeSrc" width="100%"
                                        height="400px" frameborder="0"></iframe>
                                </div>
                            </div> -->
                            <div class="tab-pane fade" id="timing" role="tabpanel" aria-labelledby="timing-tab">
                                <div class="instructor-cont" *ngFor="let cdata of servicedate | async">
                                    <div class="instructor-cont" *ngFor="let data of cdata.batchDay">
                                        <span>
                                            <div class="instructor-description">
                                                <div class="instructor-author">
                                                    <div class="author-name">
                                                        <h5>{{cdata?.course_name}} -
                                                            {{data == 'week_days' ? 'Week Days' : 'Week End'}}</h5>
                                                        <span>Starts From :-
                                                            {{cdata?.startsFrom |  date:'dd-MM-yyy'}}</span>
                                        <hr>
                                    </div>
                                </div>
                                <p>{{(cdata?.course_desc.length>150)? (cdata?.course_desc | slice:0:150)+'..':(cdata?.course_desc)}}
                                </p>
                                <div class="contanier">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="enroll-btn author-name pt-2">
                                                <p>Course Fees: &#8377;<del>{{cdata?.course_fees}}</del></p>
                                                <h5>Offer Fees: &#8377;{{cdata?.course_offerfees}}</h5>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <span class="main-btn enroll-btn float-right" (click)="navTo(cdata, cdata.id)">Enroll Now</span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            </span>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="col-lg-4">
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="course-features mt-30">
                    <div *ngFor="let cdata of servicedate | async">
                        <div *ngFor="let data of cdata.batchDay">
                            <span>
                                        <div class="instructor-description">
                                            <div class="instructor-author">
                                                <div class="author-name">
                                                    <h5>{{cdata?.course_name}} -
                                                        {{data == 'week_days' ? 'Week Days' : 'Week End'}}</h5>
                                                    <span>Starts From :-
                                                        {{cdata?.startsFrom |  date:'dd-MM-yyy'}}</span>
                            <hr>
                        </div>
                    </div>
                    <div class="contanier">
                        <div class="row">
                            <div class="col-12">
                                <div class="enroll-btn author-name pt-2">
                                    <p class="pb-15">Course Fees: &#8377;<del>{{cdata?.course_fees}}</del></p>
                                    <h5 class="pb-25">Offer Fees: &#8377;{{cdata?.course_offerfees}}</h5>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class="main-btn enroll-btn float-right" (click)="navTo(cdata, cdata.id)">Enroll Now</span>
                            </div>
                        </div>
                    </div>


                </div>
                </span>
                <hr>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>

    <!-- <div class="row">
            <div class="col-lg-12">
                <div class="releted-courses pt-65">
                    <div class="title">
                        <h3>Related Courses</h3>
                    </div>
                    <div class="row course-slied mt-30">
                        <div class="col-lg-4 cardData col-md-6 mt-30" *ngFor="let course of servicedate | async">
                            <div class="singel-course">
                                <a [routerLink]="['/Courses/CourseDetails/',course.course_title]" class="w-100"
                                    data-toggle="tooltip" title={{course.course_name}}>
                                    <div class="thum">
                                        <div class="image">
                                            <img src='{{course.course_image}}' alt="{{course.course_name}}">
                                        </div>
                                    </div>
                                    <div class="cont">
                                        <ul>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                            <li><i class="fa fa-star"></i></li>
                                        </ul>
                                        <span>({{reviewCount}} Reviews)</span>
                                        <div>

                                            <h4> {{course.course_name}} </h4>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>