<div class="singel-course">
    <a [routerLink]="['CourseDetails/',courseId]" class="w-100" data-toggle="tooltip" title={{title}}>
        <div class="thum">
            <div class="image">
                <img src='{{cardImage}}' alt="{{title}}">
            </div>
            <!-- <div class="price">
                <span>Free</span>
            </div> -->
        </div>
        <div class="cont">
            <ul>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
            </ul>
            <span>({{reviewCount}} Reviews)</span>
            <div>

                <h4> {{title}} </h4>
            </div>
        </div>
    </a>
</div>