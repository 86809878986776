import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable } from  'rxjs/Observable';
import { Observable, throwError, from } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // "Access-Control-Allow-Origin":"*"
    })
  }
  constructor(private httpClient: HttpClient) {
    console.log(environment.baseUrl);
  }

  public getCourseDetails(): Observable<any> {

    return this.httpClient
      .get<any>(environment.baseUrl + '' + environment.course, this.httpOptions)
      .pipe(
        retry(2)
      )
  }

  public getTrainerCourseDetails(): Observable<any> {

    return this.httpClient
      .get<any>(environment.baseUrl + '' + environment.trainercourse, this.httpOptions)
      .pipe(
        retry(2)
      )
  }

//   public getVegetablesDetails(): Observable<any> {

//     return this.httpClient
//       .get<any>(environment.baseUrl + '' + environment.freshVege, this.httpOptions)
//       .pipe(
//         retry(2)
//       )
//   }

//   public getHerbsDetails(): Observable<any> {

//     return this.httpClient
//       .get<any>(environment.baseUrl + '' + environment.freshHerbs, this.httpOptions)
//       .pipe(
//         retry(2)
//       )
//   }

//   public getSeasonalDetails(): Observable<any> {

//     return this.httpClient
//       .get<any>(environment.baseUrl + '' + environment.freshSeasonal, this.httpOptions)
//       .pipe(
//         retry(2)
//       )
//   }

//   public getSlotTiming(): Observable<any> {

//     return this.httpClient
//       .get<any>(environment.baseUrl + '' + environment.slotiming, this.httpOptions)
//       .pipe(
//         retry(2)
//       )
//   }

//   public getProfileDetails(user): Observable<any> {

//     return this.httpClient
//       .post(environment.baseUrl + '' + environment.profile, user, this.httpOptions)

//       .pipe(map((response) => {

//         return response

//       }));
//   }


//   public updateProfileDetails(user): Observable<any> {

//     return this.httpClient
//       .post(environment.baseUrl + '' + environment.updateProfile, user, this.httpOptions)

//       .pipe(map((response) => {

//         return response

//       }));
//   }

//   public registerUser(user): Observable<any> {
//     return this.httpClient
//       .post(environment.baseUrl + '' + environment.registerUser, user, this.httpOptions)
//       .pipe(map((response) => {
//         return response
//       }));
//   }

//   public checkoutOrder(orderData): Observable<any> {
//     return this.httpClient
//       .post(environment.baseUrl + '' + environment.checkout, orderData, this.httpOptions)
//       .pipe(map((response) => {
//         return response
//       }));
//   }

//   public sendSmsOrder(data): Observable<any> {
//     return this.httpClient
//       .post(environment.baseUrl + '' + environment.sendCodOtppost, data, this.httpOptions)
//       .pipe(map((response) => {
//         return response
//       }));
//   }

//   public verifySmsOrder(data): Observable<any> {
//     return this.httpClient
//       .post(environment.baseUrl + '' + environment.VerifyCodOtp, data, this.httpOptions)
//       .pipe(map((response) => {
//         return response
//       }));
//   }

//   public loginUser(user): Observable<any> {
//     return this.httpClient.post(environment.baseUrl + '' + environment.loginUser, user, this.httpOptions).pipe(map((response) => {
//       return response
//     }));
//   }

//   public loginSendSMS(user): Observable<any> {
//     return this.httpClient.post(environment.baseUrl + '' + environment.smsLogin, user, this.httpOptions).pipe(map((response) => {
//       return response
//     }));
//   }

//   public loginSendSMSOTP(user): Observable<any> {
//     return this.httpClient.post(environment.baseUrl + '' + environment.smsLoginOTP, user, this.httpOptions).pipe(map((response) => {
//       return response
//     }));
//   }

//   public userAddress(data): Observable<any> {
//     return this.httpClient.post(environment.baseUrl + '' + environment.addAddress, data, this.httpOptions).pipe(map((response) => {
//       return response
//     }));
//   }


}