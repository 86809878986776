<div class="teachers mt-20">
    <div class="row">
        <div class="col-sm-6" *ngFor="let teacher of data; let i=index">
            <div class="singel-teachers mt-30 text-center">
                <div class="image">
                    <img src="{{teacher.profileImage}}" alt="{{name}}">
                </div>
                <div class="cont">
                    <a href="index.html">
                        <h6>{{teacher.name}}</h6>
                    </a>
                    <span>{{teacher.position}}</span>
                </div>
            </div>
        </div>
    </div>
</div>