import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.css']
})
export class SingleBlogComponent implements OnInit {
  @Input() title: string;
  @Input() date: string;
  @Input() time: string;
  @Input() image: any;
  @Input() desc: string;

  constructor() { }

  ngOnInit(): void {
  }

}
