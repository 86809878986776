import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {
  @Input() formTitle: string;
  @Input() subTitle: string
  @Input() signin: boolean = false;
  @Input() signup: boolean = false;
  
  email = '';
  name = '';
  loc = '';
  password = '';
  phone = '';

  constructor() { }

  ngOnInit(): void {
  }

  register(){
    console.log(this.email)
    console.log(this.loc)
    console.log(this.name)
    console.log(this.password)
    console.log(this.phone)
  }

}
