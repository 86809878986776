import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeaturedCoursesComponent } from './components/featured-courses/featured-courses.component';

@Injectable({
  providedIn: 'root'
})
export class DummydataService {
  masterArray = ['Create', 'Foo', 'Bar'];
  constructor() { }

}
