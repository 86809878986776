<section id="page-banner" class="pt-105 pb-110 bg_cover" data-overlay="8" style="background-image: url(assets/images/page-banner-2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-banner-cont">
                    <h2>Tech Submit</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item"><a href="#">Blog</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Tech Submit</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="event-singel" class="pt-120 pb-120 gray-bg">
    <div class="container">
        <div class="events-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="events-left">
                        <h3>Tech Submit</h3>
                        <a href="#"><span><i class="fa fa-calendar"></i> 2 December 2018</span></a>
                        <a href="#"><span><i class="fa fa-clock-o"></i> 10:00 Am - 3:00 Pm</span></a>
                        <a href="#"><span><i class="fa fa-map-marker"></i> Rc Auditorim</span></a>
                        <img src="assets/images/blog/single-blog/e-1.jpg" alt="Event">
                        <p>Accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                            sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
                            labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="You-makelike mt-30">
                        <h4>You make like </h4>
                        <div class="singel-makelike mt-20">
                            <div class="image">
                                <img src="assets/images/blog/single-blog/e-1.jpg" alt="Image">
                            </div>
                            <div class="cont">
                                <a href="#">
                                    <h4>Introduction to machine languages</h4>
                                </a>
                                <ul>
                                    <li><a href="#"><i class="fa fa-user"></i>31</a></li>
                                    <li>$50</li>
                                </ul>
                            </div>
                        </div>
                        <div class="singel-makelike mt-20">
                            <div class="image">
                                <img src="assets/images/blog/single-blog/e-1.jpg" alt="Image">
                            </div>
                            <div class="cont">
                                <a href="#">
                                    <h4>How to build a basis game with java </h4>
                                </a>
                                <ul>
                                    <li><a href="#"><i class="fa fa-user"></i>31</a></li>
                                    <li>$50</li>
                                </ul>
                            </div>
                        </div>
                        <div class="singel-makelike mt-20">
                            <div class="image">
                                <img src="assets/images/blog/single-blog/e-1.jpg" alt="Image">
                            </div>
                            <div class="cont">
                                <a href="#">
                                    <h4>Basic accounting from primary</h4>
                                </a>
                                <ul>
                                    <li><a href="#"><i class="fa fa-user"></i>31</a></li>
                                    <li>$50</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>