import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
  @Input() comments: string;
  @Input() name: string;
  @Input() education: string;
  @Input() profileImage: any;

  constructor() { }

  ngOnInit(): void {
  }

}
