<section id="count-down-part" class="bg_cover pt-70 pb-120" data-overlay="8" style="background-image: url(assets/images/bg-2.jpg)">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6">
                <div class="count-down-cont footer-address mt-40">
                    <div class="footer-title pb-25">
                        <h6>Contact Us</h6>
                    </div>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fa fa-building-o"></i>
                            </div>
                            <div class="cont">
                                <p>Skill EnableHR Pvt LTD,<br>#L139, Baskar's Plaza, 5the Main, 15th Cross, Sector 6, HSR Layout, Bangalore 560102.</p>
                            </div>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fa fa-phone"></i>
                            </div>
                            <div class="cont">
                                <a href="tel:+919620666883" target="_blank">
                                    <p>9620666883 / </p>
                                </a>
                                <a href="tel:+919620666944" target="_blank">
                                    <p>9620666944</p>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fa fa-envelope-o"></i>
                            </div>
                            <div class="cont">
                                <p><a href="mailto:info@skillenablehr.com" class="__cf_email__" data-cfemail="info@skillenablehr.com">info@skillenablehr.com</a></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-md-8">
                <div class="category-form category-form-3 pt-50">
                    <div class="form-title text-center">
                        <span>Contact Us</span>
                    </div>
                    <div class="main-form">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.valid,loginForm.value)">
                            <div class="singel-form">
                                <input type="text" placeholder="Name*" formControlName="name">
                            </div>
                            <div class="singel-form">
                                <input type="email" placeholder="Email id*" formControlName="email">
                            </div>
                            <div class="singel-form">
                                <input type="tel" placeholder="Mobile Number*" formControlName="phone">
                            </div>
                            <div class="singel-form">
                                <textarea type="text" style="width: 100%;" formControlName="message" placeholder="Message"></textarea>
                            </div>
                            <div class="singel-form mt-0">
                                <a class="w-100">
                                    <button class="main-btn" type="submit">Submit</button>
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div id="counter-part" class="bg_cover pt-25 pb-70 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="singel-counter counter-3 text-center mt-40">
                    <span><span class="counter">8,000</span>+</span>
                    <p>Students enrolled</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="singel-counter counter-3 text-center mt-40">
                    <span><span class="counter">20</span>+</span>
                    <p>Years</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="singel-counter counter-3 text-center mt-40">
                    <span><span class="counter">11,000</span>+</span>
                    <p>People certified</p>
                </div>
            </div>
        </div>
    </div>
</div> -->