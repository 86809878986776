<section id="slider-part" class="slider-active">
    <div *ngFor="let item of sliderData; let i=index">
        <div class="single-slider bg_cover pt-150" style="background-image: url({{item.bgImage}})" data-overlay="4">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-xl-7 ">
                        <div class="slider-cont">
                            <h1 data-animation="bounceInLeft" data-delay="1s">{{item.title}}</h1>
                            <p data-animation="fadeInUp" data-delay="1.3s">{{item.details}}</p>
                            <ul>
                                <!-- <li><a data-animation="fadeInUp" data-delay="1.6s" class="main-btn" routerLink="/Courses">Read More</a></li> -->
                                <li><a data-animation="fadeInUp" data-delay="1.9s" class="main-btn main-btn-2" routerLink="/{{item.getStartedLink}}">Get Started</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="category-part">
    <div class="container">
        <div class="category pt-40 pb-40">
            <div class="row">
                <div class="col-lg-4">
                    <div class="category-text pt-40">
                        <h2>Best platform to learn everything</h2>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2">
                    <div class="row category-slied mt-40">
                        <div class="col-lg-4 cardData" *ngFor="let course of coursedate | async">
                            <a [routerLink]="'/Courses'">
                                <span class="singel-category text-center color-1">
                                    <span class="icon">
                                        <img class="course-icon" src='{{course.service_image}}' alt="{{course.service_title}}">
                                    </span>
                                <span class="cont">
                                        <span>{{(course?.service_title.length>11)? (course?.service_title | slice:0:11)+'..':(course?.service_title)}}</span>
                                </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="about-part" class="pt-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="section-title mt-50">
                    <h5>About us</h5>
                    <h2>Welcome to Skill EnableHR</h2>
                </div>
                <div class="about-cont">
                    <p>Skill EnableHR is a leading Training Institute in Bangalore providing Quality and Affordable Training Services, Located Strategically at hsr Layout 1st Stage.</p>
                    <p>Our Trainers are Working Professionals with minimum 10+ Years of Experience and have In Depth Real Time Exposure which helps our students in getting a edge over other Institutes. </p>
                    <p>Provide Excellent Lab Facilities and 100 % Placement Assistance for our Students.</p>
                    <a routerLink="/about" class="main-btn mt-55">Learn More</a>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <div class="about-event mt-50">
                    <div class="event-title">
                        <h3>Upcoming Events</h3>
                    </div>
                    <ul>
                        <li *ngFor="let news of servicedate | async">
                            <app-latest-feed ddmmyyyy='{{news.startsFrom}}' title='{{news.course_name}}'>
                            </app-latest-feed>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="about-bg">
        <img src="assets/images/about/bg-1.png" alt="About">
    </div>
</section>
<!-- <section id="apply-aprt" class="pb-120">
    <div class="container">
        <div class="apply">
            <div class="row no-gutters">
                <div class="col-lg-6">
                    <div class="apply-cont apply-color-1">
                        <h3>Apply for fall 2020</h3>
                        <p>This course will cover concepts of Automation Anywhere which include Web automation, Email
                            Automation, set Privileges and Permissions, PDF Integration, XML Automation, Object Cloning,
                            Data Manipulation, Image Recognition, Exception
                            Handling and many more.</p>
                        <a href="#" class="main-btn">Apply Now</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="apply-cont apply-color-2">
                        <h3>Apply for updates</h3>
                        <p>This course will cover concepts of Automation Anywhere which include Web automation, Email
                            Automation, set Privileges and Permissions, PDF Integration, XML Automation, Object Cloning,
                            Data Manipulation, Image Recognition, Exception
                            Handling and many more.</p>
                        <a href="#" class="main-btn">Apply Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section id="about-page" class="pt-70 pb-110">
    <div class="container">
        <!-- <div class="row">
            <div class="col-lg-12">
                <div class="section-title mt-50">
                    <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                        <h5>Our services</h5>
                        <h2>Application Development</h2>
                    </a>
                </div>
                <div class="about-cont">
                    <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                        <p>With the help of our highly qualified and skilled IT professionals, we have been able to serve our clients with valuable Web Application Development & Mobile Application Services. Our web & mobile application development and custom
                            programming services include everything from a simple Content Management System enabled website to the most complex web-based internet applications, e-commerce portals, and social networking development services.</p>
                    </a>
                </div>
            </div>
        </div>
        <div class="row ourServices">
            <div class="col-sm-4 col-6 text-center">
                <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                    <img src="assets/images/services/icons/mob-web.png" alt='service' />
                    <small>Mobile & Web Apps</small>
                </a>
            </div>
            <div class="col-sm-4 col-6 text-center">
                <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                    <img src="assets/images/services/icons/ecommerce.png" alt='service' />
                    <small>Ecommerce</small>
                </a>
            </div>
            <div class="col-sm-4 col-6 text-center">
                <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                    <img src="assets/images/services/icons/ui-ux-design.png" alt='service' />
                    <small>UI/UX Design</small>
                </a>
            </div>
            <div class="col-6 text-center">
                <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                    <img src="assets/images/services/icons/digital.png" alt='service' />
                    <small>Digital Marketing</small>
                </a>
            </div>
            <div class="col-6 text-center">
                <a [routerLink]="['/OurService']" routerLinkActive="router-link-active">
                    <img src="assets/images/services/icons/branding.png" alt='service' />
                    <small>Payment Integration</small>
                </a>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title mt-50">
                    <a [routerLink]="['/Recruitment']" routerLinkActive="router-link-active">
                        <h2>IT Hiring/Staffing/Recruitment</h2>
                        <h4 style="color:#07294d">1000+ recruitment</h4>
                    </a>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="about-cont">
                    <a [routerLink]="['/Recruitment']" routerLinkActive="router-link-active">
                        <p>We at Professional Training Institute committed to delivering best advance technology training in Bangalore through the practical system. Our unique teaching method focuses on practical and hands-on training to the student so that they are able to the face any kind of interview. Skill EnableHR the world’s ace development training organization with an aim to bridge the gap between the demands of the industry and the curriculum of educational institutions. With centers across India, the institute is a platform where young minds are given the opportunity to build successful careers.</p>
                        <p>
                            ” Skill EnableHR is a place where businesses find talent and dreams take flight.”
                        </p>
                    </a>
                </div>
            </div>
            <div class="col-sm-6">
                <img src="assets/images/recruit/gather.png" alt="servicePage" class="img-fluid">
            </div>
        </div>
        <div class="row flex-sm-row-reverse">
            <div class="col-lg-12">
                <div class="section-title mt-50">
                    <a [routerLink]="['/Recruitment']" routerLinkActive="router-link-active">
                        <h2>Workday Integration Service</h2>
                        <h4 style="color:#07294d">Fixed Price and T&M Support</h4>
                    </a>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="about-cont">
                    <a [routerLink]="['/Recruitment']" routerLinkActive="router-link-active">
                        <p>We at <b>Skill EnableHR</b> provide best in class support for <b>Workday Integration Services</b> across functions, both in terms of Project Support (Fixed Price) and <b>Staff Augmentation Support</b> (Time and Material).</p>
                    </a>
                </div>
                <div class="about-singel-items">
                    <ul class="pl-3">
                        <li>
                            <p class="pt-3 pb-2">&#9675; Workday Integration Development and Support experience across Workday functions mainly HCM.</p>
                        </li>
                        <li>
                            <p class="pb-2">&#9675; STUDIO integration experience and capable to design, develop and test complex studio integrations independently</p>
                        </li>
                        <li>
                            <p class="pb-2">&#9675; Strong experience with Cloud Connectors, CCW, EIB, DT's, XML/ XSLT, PICOF, and Workday STUDIO custom integrations.....</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <img src="assets/images/recruit/concept.png" alt="servicePage" class="img-fluid">
            </div>
        </div>
    </div>
</section>
<!-- <section id="course-part" class="pt-50 pb-50 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title pb-25">
                    <h5>Projects</h5>
                    <h2>Featured Projects</h2>
                </div>
            </div>
        </div>
        <div class="row course-slied mt-30">
            <div class="col-lg-4 cardData" *ngFor="let project of projects">
                <app-featured-project title='{{project.title}}' details='{{project.details}}' language='{{project.language}}' cardImage={{project.cardImage}} courseId="{{project.id}}" studentsCount='20' reviewCount='4.5' favouriteCount='6'>
                </app-featured-project>
            </div>
        </div>
    </div>
</section> -->
<section id="testimonial" class="bg_cover pt-50 pb-50" data-overlay="8" style="background-image: url(assets/images/bg-2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title pb-40">
                    <h5>Testimonial</h5>
                    <h2>What they say</h2>
                </div>
            </div>
        </div>
        <div class="row testimonial-slied mt-40">
            <div class="col-lg-6" *ngFor="let student of testimonialsList; let i=index">
                <app-testimonial comments='{{student.comments}}' name='{{student.name}}' education='{{student.education}}' profileImage='{{student.profileImage}}'></app-testimonial>
            </div>
        </div>
    </div>
</section>
<section id="course-part" class="pt-50 pb-50 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title pb-45">
                    <h5>Our Courses</h5>
                    <h2>Featured Courses</h2>
                </div>
            </div>
        </div>
        <div class="row course-slied mt-30">
            <div class="col-lg-4 cardData" *ngFor="let course of coursedate | async">
                <app-featured-courses title='{{course.service_title}}' tuitor='{{course.service_title}}' cardImage={{course.service_image}} courseId="{{course.id}}" studentsCount='20' reviewCount='4.5' favouriteCount='6'>
                </app-featured-courses>
            </div>
        </div>
    </div>
</section>
<section id="teachers-part" class="pb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title mt-50">
                    <h5>Featured Teachers</h5>
                    <!-- <h2>Meet Our teachers</h2> -->
                </div>
                <div class="teachers-cont">
                    <p>Our team is your team. When your mission is to be better, faster and smarter, you need the best people driving your vision forward.</p>
                    <a href="https://firestoretest-f3d0f.web.app/register" target="_blank" class="main-btn mt-15">Career with us</a>
                </div>
            </div>
            <!-- <div class="col-lg-6 offset-lg-1">
                <app-profile-card [data]=teachersList></app-profile-card>
            </div> -->
        </div>
    </div>
</section>