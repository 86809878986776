import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: any;
  role: any = false;

  constructor(public auth: AuthService) {
    // this.user = this.auth.user;
    setInterval(() => {
      this.user = JSON.parse(localStorage.getItem('studentMaster'));
    }, 5000);
    console.log(this.user);
  }

  ngOnInit(): void {
    // this.user = this.auth.user;
    this.user = JSON.parse(localStorage.getItem('studentMaster'));

    console.log(this.user);
  }

}
