<footer id="footer-part">
    <div class="footer-top pt-40 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about mt-40">
                        <div class="logo">
                            <a href="#"><img src="assets/images/logo.jpg" alt="Logo"></a>
                        </div>
                        <p>Skill EnableHR to be read as Skill enabler founded by Software Professional, who has 20+ years of IT industry Experience with motive to Provide high quality, cost effective information technology business solutions.</p>
                        <ul class="mt-20">
                            <li><a href="https://www.facebook.com/skill.enablehr.1" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/SkillEnablehr" target="_blank"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/skillenabler/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/in/skill-enablehr-97174419b/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="footer-link mt-40">
                        <div class="footer-title pb-25">
                            <h6>Sitemap</h6>
                        </div>
                        <ul>
                            <li><a href="/Home" routerLinkActive="router-link-active"><i class="fa fa-angle-right"></i>Home</a></li>
                            <li><a [routerLink]="['/about']" routerLinkActive="router-link-active"><i class="fa fa-angle-right"></i>About us</a></li>
                            <!-- <li><a [routerLink]="['/Courses']" routerLinkActive="router-link-active"><i class="fa fa-angle-right"></i>Courses</a></li> -->
                            <li><a [routerLink]="['/Contact']" routerLinkActive="router-link-active"><i class="fa fa-angle-right"></i>Contact</a></li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="footer-link support mt-40">
                        <div class="footer-title pb-25">
                            <h6>Support</h6>
                        </div>
                        <ul>
                            <li><a href="/Home" routerLinkActive="router-link-active"><i class="fa fa-angle-right"></i>FAQS</a></li>
                            <li><a href="/Home" routerLinkActive="router-link-active"><i class="fa fa-angle-right"></i>Terms and Conditions</a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-4 col-md-6">
                    <div class="footer-address mt-40">
                        <div class="footer-title pb-25">
                            <h6>Contact Us</h6>
                        </div>
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="fa fa-building-o"></i>
                                </div>
                                <div class="cont">
                                    <p>Skill EnableHR Pvt LTD<br> #L139, Baskar's Plaza, 5the Main, 15th Cross, Sector 6, HSR Layout, Bangalore 560102.</p>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="fa fa-phone"></i>
                                </div>
                                <div class="cont">
                                    <a href="tel:+919620666883" target="_blank">
                                        <p>9620666883 / </p>
                                    </a>
                                    <a href="tel:+919620666944" target="_blank">
                                        <p>9620666944</p>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="fa fa-envelope-o"></i>
                                </div>
                                <div class="cont">
                                    <p><a href="mailto:info@skillenablehr.com" class="__cf_email__" data-cfemail="info@skillenablehr.com">info@skillenablehr.com</a></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>